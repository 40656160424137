/* eslint-disable */

import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Card, Collapse } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PageFelidsObject from "./PageFelidsObject";
import splitSpace from "utils/splitSpace";
import { BLOG, REF_MODAL, REF, PAGE } from "utils/constant";
import SelectedBlogs from "./SelectedBlogs";
import SelectedPages from "./SelectedPages";

const ObjectComponent = ({
  previousKey,
  keyName,
  data,
  register,
  errors,
  removeIndexFromArray,
  getPageInfoByPath,
  updatePageInfo,
  checkIfAllowedExtension,
  isAllowedToUploadFile,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded((pldExpand) => !pldExpand);
  };

  return keyName === REF ? (
    data[REF][REF_MODAL] === BLOG ? (
      <SelectedBlogs
        path={`${previousKey}.${keyName}`}
        data={data}
        updatePageInfo={updatePageInfo}
      />
    ) : data[REF][REF_MODAL] === PAGE ? (
      <SelectedPages
        path={`${previousKey}.${keyName}`}
        data={data}
        updatePageInfo={updatePageInfo}
      />
    ) : null
  ) : (
    <Card py={3} sx={{ marginTop: 3 }} key={`${previousKey}.${keyName}`}>
      <MDBox py={2}>
        <MDBox
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent={"space-between"}
          onClick={handleExpandClick}
          sx={{ marginRight: 3, cursor: "pointer" }}
          alignItems="center"
        >
          <MDTypography
            mx={3}
            fontSize={15}
            component="a"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {splitSpace(keyName)}
          </MDTypography>
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </MDBox>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <MDBox mx={5}>
            <PageFelidsObject
              data={data[keyName]}
              previousKey={!previousKey ? keyName : previousKey + "." + keyName}
              register={register}
              errors={errors}
              removeIndexFromArray={removeIndexFromArray}
              getPageInfoByPath={getPageInfoByPath}
              updatePageInfo={updatePageInfo}
              checkIfAllowedExtension={checkIfAllowedExtension}
              isAllowedToUploadFile={isAllowedToUploadFile}
            />
          </MDBox>
        </Collapse>
      </MDBox>
    </Card>
  );
};

export default ObjectComponent;
