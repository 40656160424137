/* eslint-disable */

const firebaseApp = require("firebase/app");
const firebaseMessaging = require("firebase/messaging");

import ownerApi from "./api/owner";

const firebaseConfig = {
  apiKey: "AIzaSyARmXPWwnOz7ogTivA4WreV4MkBxesiegk",
  authDomain: "baas-e9932.firebaseapp.com",
  projectId: "baas-e9932",
  storageBucket: "baas-e9932.appspot.com",
  messagingSenderId: "285273855414",
  appId: "1:285273855414:web:13eb449ce39cadbeabdd17",
  measurementId: "G-S0JQNM6TPL",
};

const app = firebaseApp.initializeApp(firebaseConfig);

const messaging = firebaseMessaging.getMessaging(app);

export function requestPermission(notifications) {
  if (!("Notification" in window)) return;
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // console.log("Notification permission granted.");

      firebaseMessaging
        .getToken(messaging, {
          vapidKey:
            "BHBYOHVIa10swGa99fn31UCx7SVWV61nWbsryBX9kzhxv877TXgxr6yhDmiV1-WKasUu7sA3LjGP3u-SYJ1GKqE",
        })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("currentToken: ", currentToken);
            if (!notifications.includes(currentToken)) {
              ownerApi.updateOwnerNotification(currentToken);
            }
          } else {
            // console.log("Can not get token");
          }
        });
    } else {
      // console.log("Do not have permission!");
    }
  });
}

export const onMessageListener = (callBack) =>
  firebaseMessaging.onMessage(messaging, (payload) => {
    callBack(payload);
    return payload;
  });
