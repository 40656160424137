/* eslint-disable */
import React from "react";

import PropTypes from "prop-types";
import { Box } from "@mui/system";

import PageFelidsArray from "./PageFelidsArray";
import ObjectComponent from "./ObjectComponent";
import PageInput from "./PageInput";

function PageFelidsObject({
  data,
  previousKey,
  isArray,
  register,
  errors,
  removeIndexFromArray,
  isFile,
  updatePageInfo,
  getPageInfoByPath,
  checkIfAllowedExtension,
  isAllowedToUploadFile,
}) {
  return Object.keys(data).map((key, index) => (
    <Box key={index}>
      {data[key] instanceof File || typeof data[key] !== "object" ? (
        <PageInput
          value={data[key]}
          registerParams={!previousKey ? key : `${previousKey}.${key}`}
          isArray={isArray}
          register={register}
          errors={errors}
          removeIndexFromArray={removeIndexFromArray}
          isFile={isFile ? isFile : checkIfAllowedExtension(key)}
          getPageInfoByPath={getPageInfoByPath}
          updatePageInfo={updatePageInfo}
          isAllowedToUploadFile={isAllowedToUploadFile}
        />
      ) : typeof data[key] === "object" && Array.isArray(data[key]) ? (
        <PageFelidsArray
          previousKey={previousKey}
          keyName={key}
          data={data[key]}
          register={register}
          errors={errors}
          removeIndexFromArray={removeIndexFromArray}
          isFile={isFile ? isFile : checkIfAllowedExtension(key)}
          getPageInfoByPath={getPageInfoByPath}
          updatePageInfo={updatePageInfo}
          checkIfAllowedExtension={checkIfAllowedExtension}
          isAllowedToUploadFile={isAllowedToUploadFile}
        />
      ) : typeof data[key] === "object" && !Array.isArray(data[key]) ? (
        <ObjectComponent
          previousKey={previousKey}
          keyName={key}
          data={data}
          register={register}
          errors={errors}
          removeIndexFromArray={removeIndexFromArray}
          getPageInfoByPath={getPageInfoByPath}
          updatePageInfo={updatePageInfo}
          checkIfAllowedExtension={checkIfAllowedExtension}
          isAllowedToUploadFile={isAllowedToUploadFile}
        />
      ) : null}
    </Box>
  ));
}

PageFelidsObject.defaultProps = {
  isArray: false,
  previousKey: null,
};

PageFelidsObject.propTypes = {
  data: PropTypes.any.isRequired,
  previousKey: PropTypes.string,
  isArray: PropTypes.bool,
  register: PropTypes.any.isRequired,
  checkIfAllowedExtension: PropTypes.any.isRequired,
};

export default PageFelidsObject;
