/* eslint-disable */

import React, { useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";

import { Card, CircularProgress, Dialog, Modal, Stack, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import PropTypes from "prop-types";
import _ from "lodash";

import pageApi from "api/page";
import { toast } from "react-toastify";
import splitSpace from "utils/splitSpace";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";

import "cropperjs/dist/cropper.css";
import base64ToFile from "utils/base64ToFile";
import { IMAGE_HEIGHT, IMAGE_WIDTH, IGNORE_INPUT_NAME, IMAGE_FILE, PDF_FILE } from "utils/constant";
import ModalFiles from "./ModalFiles";
import MyPdfViewer from "components/MyPdfViewer";

function PageInput({
  value,
  registerParams,
  isArray,
  register,
  removeIndexFromArray,
  errors,
  isFile,
  getPageInfoByPath,
  updatePageInfo,
  isOnlyItem,
}) {
  const { pageId } = useParams();

  const [openModalFile, setOpenModalFile] = useState(false);
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState();
  const [loadingImage, setLoadingImage] = useState(value instanceof File ? false : true);

  const getInputName = () => {
    const split = registerParams.split(".");
    if (split && split.length > 1) {
      const name = split[split.length - 1];
      if (isNaN(name)) return splitSpace(name);
      else return `${splitSpace(split[split.length - 2])} ${parseInt(name) + 1}`;
    } else {
      return splitSpace(registerParams);
    }
  };

  const removeButtonHandlerFromArray = async () => {
    removeIndexFromArray(registerParams);
  };

  const onCloseHandler = () => setOpenModalFile(false);
  const onOpenHandler = () => setOpenModalFile(true);

  const checkRTL = (s) => {
    var ltrChars =
        "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
        "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
      rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
      rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

    return rtlDirCheck.test(s);
  };

  const acceptFile = () => {
    let accept = {};
    if (isFile) {
      isFile.htmlFile.map((e) => (accept[e] = []));
    }
    return accept;
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (isFile.name === IMAGE_FILE) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      updatePageInfo(registerParams, acceptedFiles[0]);
    }
    onCloseHandler();
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFile(),
  });

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const file = base64ToFile(cropper.getCroppedCanvas().toDataURL(), "image.png");
      updatePageInfo(registerParams, file);
      setImage(null);
    }
  };

  const getWidthAndHeight = () => {
    const widthPath = registerParams.split(".").slice(0, -1).concat(IMAGE_WIDTH).join(".");
    const heightPath = registerParams.split(".").slice(0, -1).concat(IMAGE_HEIGHT).join(".");

    const width = getPageInfoByPath(widthPath);
    const height = getPageInfoByPath(heightPath);

    if (_.isNumber(width) && _.isNumber(height)) return width / height;
    return false;
  };

  const selectFileFromArchive = (file) => {
    if (isFile.name === IMAGE_FILE) {
      setImage(file);
    } else {
      updatePageInfo(registerParams, file);
    }
    onCloseHandler();
  };

  return (
    <MDBox mt={1} py={1} key={registerParams}>
      {isFile ? (
        <>
          <MDTypography
            component="a"
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{ mr: 3 }}
          >
            {getInputName()}
          </MDTypography>
          <MDButton variant="gradient" color="success" onClick={onOpenHandler}>
            Update File
          </MDButton>
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            <Stack direction="column" spacing={3}>
              {isFile.name === IMAGE_FILE
                ? value && (
                    <>
                      <img
                        src={value instanceof File ? URL.createObjectURL(value) : value}
                        width="200"
                        height="auto"
                        onLoad={() => setLoadingImage(false)}
                        style={{ display: loadingImage && "none", margin: "auto" }}
                      />
                      {loadingImage && (
                        <MDBox
                          sx={{
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress color="inherit" size="2rem" sx={{ marginLeft: 1 }} />
                        </MDBox>
                      )}
                    </>
                  )
                : isFile.name === PDF_FILE
                ? value && (
                    <MyPdfViewer
                      file={value instanceof File ? URL.createObjectURL(value) : value}
                    />
                  )
                : null}
            </Stack>
          </MDTypography>
          <ModalFiles
            openModalFile={openModalFile}
            onCloseHandler={onCloseHandler}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            fileName={isFile.name}
            selectFileFromArchive={selectFileFromArchive}
          />
        </>
      ) : typeof value === "boolean" ? (
        <>
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {getInputName()}
          </MDTypography>
          <Switch defaultChecked={value} {...register(registerParams, { required: true })} />
        </>
      ) : (
        IGNORE_INPUT_NAME.indexOf(getInputName()) === -1 && (
          <MDInput
            type={typeof value === "number" ? "number" : "text"}
            label={`${getInputName()}`}
            register={register(registerParams, { required: true })}
            error={!!_.get(errors, registerParams, _.isMatch).message}
            fullWidth
            multiline={true}
            dir={checkRTL(value) ? "RTL" : "LTR"}
          />
        )
      )}
      <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
        {isArray && isOnlyItem && (
          <MDButton
            variant="gradient"
            color="error"
            onClick={removeButtonHandlerFromArray}
            sx={{ marginTop: 1, marginBottom: 1, display: "flex" }}
          >
            Remove
          </MDButton>
        )}
      </MDTypography>
      <Dialog open={!!image}>
        {isFile?.name === IMAGE_FILE && (
          <Card>
            <div style={{ width: "100%" }}>
              {getWidthAndHeight() === false ? (
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0.1}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              ) : (
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0.1}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={false}
                  autoCropArea={1}
                  checkOrientation={false}
                  aspectRatio={getWidthAndHeight()}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              )}
              <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                <Stack direction="row">
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={getCropData}
                    sx={{ marginTop: 1, marginBottom: 1, display: "flex" }}
                  >
                    Save
                  </MDButton>
                  <MDBox sx={{ width: 20 }}></MDBox>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => setImage(null)}
                    sx={{ marginTop: 1, marginBottom: 1, display: "flex" }}
                  >
                    Cancel
                  </MDButton>
                </Stack>
              </MDBox>
            </div>
          </Card>
        )}
      </Dialog>
    </MDBox>
  );
}

PageInput.defaultProps = {
  isArray: false,
};

PageInput.propTypes = {
  value: PropTypes.any.isRequired,
  registerParams: PropTypes.string.isRequired,
  isArray: PropTypes.bool,
  register: PropTypes.any.isRequired,
};

export default PageInput;
