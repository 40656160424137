import AxiosInstance from "./AxiosInstance";

const error = {
  postError: async (body) => {
    const res = await AxiosInstance.post(`/error`, body);
    return res.data;
  },
};

export default error;
