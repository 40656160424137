/* eslint-disable */
import create from "zustand";

const domainStore = (set) => ({
  domainStore: null,
  setDomainStore: (domainStoreParams) => set(() => ({ domainStore: domainStoreParams })),
  resetData: () => set(() => ({ domainStore: null })),
});

export const useDomainStore = create(domainStore);
