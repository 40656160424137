/* eslint-disable */

import _ from "lodash";

function deepMap(obj, fn) {
  return _.mapValues(obj, (x) => {
    if (_.isPlainObject(x)) {
      return deepMap(x, fn);
    } else if (_.isArray(x)) {
      // return _.map(x, (y) => deepMap(y, fn));
      return _.map(x, (y) => (_.isObject(y) ? deepMap(y, fn) : y));
    } else {
      return fn(x);
    }
  });
}

export default deepMap;
