/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Card, CircularProgress, Paper, Stack } from "@mui/material";

import archiveApi from "api/archive";
import fileExtensionApi from "api/file-extension";

import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { Masonry } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { IMAGE_FILE, PDF_FILE } from "utils/constant";
import MyPdfViewer from "components/MyPdfViewer";
import ImageWithLoadingSpinner from "components/ImageWithLoadingSpinner";

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

function DomainArchive() {
  const { domainName } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainArchive, setDomainArchive] = useState({});
  const [fileExt, setFileExt] = useState(null);
  const [chosenArchiveFile, setChosenArchiveFile] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const getDomainArchive = async () => {
    setLoading(true);
    try {
      const res = await archiveApi.get(domainName, page, chosenArchiveFile);
      if (res.pagination.current_page === res.pagination.total_pages) setIsLastPage(true);
      setDomainArchive((prevData) => ({
        ...prevData,
        [chosenArchiveFile]: [...prevData[chosenArchiveFile], ...res.data],
      }));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getAllExtension = async () => {
    try {
      const res = await fileExtensionApi.getAllExt();
      let newData = {};
      res.map((e) => {
        newData[e.name] = [];
      });
      setDomainArchive(newData);
      setChosenArchiveFile(res[0].name);
      setFileExt(res);
    } catch (error) {}
  };

  useEffect(() => {
    getAllExtension();
  }, [domainName]);

  useEffect(() => {
    if (!chosenArchiveFile) return;
    getDomainArchive();
  }, [page, chosenArchiveFile]);

  const onChangeArchiveFiles = async (fileName) => {
    setChosenArchiveFile(fileName);
    setDomainArchive((prevData) => ({
      ...prevData,
      [fileName]: [],
    }));
    setPage(1);
    setIsLastPage(false);
  };

  const loadMoreHandler = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return fileExt ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ display: "flex", flexDirection: "row", minHeight: "100vh", marginBottom: 10 }}>
        {/* {domainArchive?.length > 0 ? ( */}
        <>
          <Card
            sx={{
              width: 200,
              height: fileExt.length * 80,
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {fileExt.map((ext) => (
                <div key={ext.name}>
                  {ext.name === chosenArchiveFile ? (
                    <Card>
                      <MDTypography
                        variant="h6"
                        sx={{ marginLeft: 3, cursor: "pointer", paddingY: 1 }}
                        onClick={() => onChangeArchiveFiles(ext.name)}
                      >
                        {ext.name.replace("_", "").toUpperCase()}
                      </MDTypography>
                    </Card>
                  ) : (
                    <MDTypography
                      variant="h6"
                      sx={{ marginLeft: 3, cursor: "pointer" }}
                      onClick={() => onChangeArchiveFiles(ext.name)}
                    >
                      {ext.name.replace("_", "").toUpperCase()}
                    </MDTypography>
                  )}
                </div>
              ))}
            </MDBox>
          </Card>
          <Card sx={{ flex: 1, padding: 5, marginLeft: 3 }}>
            <Masonry columns={3} spacing={1}>
              {domainArchive[chosenArchiveFile]?.length > 0 ? (
                domainArchive[chosenArchiveFile].map((file) => (
                  <div key={file._id}>
                    {chosenArchiveFile === IMAGE_FILE && (
                      <ImageWithLoadingSpinner
                        src={`${file.name}?w=162&auto=format`}
                        srcSet={`${file.name}?w=162&auto=format&dpr=2 2x`}
                        // alt={item.title}
                        loading="lazy"
                        style={{
                          borderBottomLeftRadius: 4,
                          borderBottomRightRadius: 4,
                          display: "block",
                          width: "100%",
                        }}
                      />
                    )}
                    {chosenArchiveFile === PDF_FILE && <MyPdfViewer file={file.name} />}
                  </div>
                ))
              ) : (
                <MDTypography>There is no archive yet</MDTypography>
              )}
            </Masonry>
            {domainArchive[chosenArchiveFile]?.length > 0 && !isLastPage && (
              <MDButton
                sx={{ alignSelf: "center" }}
                size="small"
                color="success"
                onClick={loadMoreHandler}
              >
                Load More
                {loading && (
                  <CircularProgress color={"white"} size="0.7rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            )}
          </Card>
        </>
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default DomainArchive;
