/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Card, CircularProgress } from "@mui/material";

import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import pageApi from "api/page";

import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

import { useDomainStore } from "store";

const COLUMNS = [
  { Header: "Pages", accessor: "page_route", align: "left" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "Views", accessor: "view", align: "center" },
  { Header: "META", accessor: "meta", align: "center" },
  { Header: "Views Analytics", accessor: "viewAnalytics", align: "center" },
  { Header: "Edit", accessor: "action", align: "center" },
];

function CustomPages() {
  const { domainName } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const domainStore = useDomainStore((state) => state.domainStore);

  const query = new URLSearchParams(location.search);
  const route = query.get("route");

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pagesInfo, setPagesInfo] = useState(null);

  const navigateToPage = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}`);
  };
  const navigateToPageMETA = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}/meta`);
  };
  const navigateToPageAnalysis = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}/analysis`);
  };
  const navigateToCreateCustomPage = () => {
    navigate(`/domain/${domainName}/page/create?route=${route}`);
  };

  const getPagesInfo = async () => {
    try {
      const isMETAActivate = domainStore.data.find((e) => e.domain === domainName).isMETAActivate;
      const res = await pageApi.getPageByDomainAndRouteWithAnalysis(domainName, route);

      const newPagesInfo = [];
      res.map((page) => {
        newPagesInfo.push({
          page_route: domainName + page.page_route,
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.views}
            </MDTypography>
          ),
          meta: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton
                disabled={!isMETAActivate}
                color="primary"
                size="small"
                onClick={() => navigateToPageMETA(page._id)}
              >
                META
              </MDButton>
            </MDBox>
          ),
          viewAnalytics: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="info" size="small" onClick={() => navigateToPageAnalysis(page._id)}>
                Analysis
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToPage(page._id)}>
              <MDButton color="success" size="small">
                Edit
              </MDButton>
            </MDBox>
          ),
        });
      });
      setPagesInfo(newPagesInfo);
    } catch (error) {
      navigate(`/domain/${domainName}/page`);
    }
  };

  useEffect(() => {
    if (!domainStore) return;
    if (!route) {
      navigate(`/domain/${domainName}/page`);
    }
    getPagesInfo();
  }, [domainName, domainStore]);

  return pagesInfo ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card sx={{ marginTop: 7 }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Pages {route}
            </MDTypography>
            <MDButton color="success" size="small" onClick={() => navigateToCreateCustomPage()}>
              Create
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: pagesInfo }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              canSearch
            />
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default CustomPages;
