/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Grid } from "@mui/material";
import { Stack } from "@mui/system";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import errorApi from "api/error";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const myErrorHandler = async () => {
    setIsLoading(true);
    await errorApi.postError({
      message: error.message,
      stack: error.stack,
      location: location.pathname,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    myErrorHandler();
  }, []);

  return (
    <MDBox>
      <Grid
        container
        sx={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack spacing={5}>
            <MDTypography sx={{ textAlign: "center" }}>
              Error Happened We are working on it right now
              <br />
              {isLoading ? "submitting error..." : "We receive the bugs, we are working on it"}
            </MDTypography>
            <MDButton
              color="info"
              onClick={() => resetErrorBoundary()}
              sx={{ width: 200, alignSelf: "center" }}
            >
              Refresh you Page
            </MDButton>
          </Stack>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default ErrorFallback;
