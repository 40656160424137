/* eslint-disable */

import React, { useEffect, useState, useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import pageApi from "api/page";
import fileExtensionApi from "api/file-extension";
import { FormProvider, useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import PageFelidsObject from "layouts/page/components/PageFelidsObject";
import _ from "lodash";
import { useMaterialUIController } from "context";
import { REF_ID } from "utils/constant";
import getObjectPaths from "utils/getObjectPaths";
import getAllThePathForFile from "utils/getAllThePathForFile";
import asyncMap from "utils/asyncMap";
import deepMap from "utils/deepMap";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";
import replaceInstanceFileWithEmptyArray from "utils/replaceInstanceFileWithEmptyArray";

export default function UpdatePage() {
  const { pageId, domainName } = useParams();

  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [fileExtension, setFileExtension] = useState(null);
  const [initPageData, setInitPageData] = useState(null);
  const [isCustomPage, setIsCustomPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [progressFile, setProgressFile] = useState(null);

  const methods = useForm();
  const pageInfo = methods.watch();

  const getPageInfo = async () => {
    try {
      const res = await pageApi.getPageById(pageId);
      setIsCustomPage(res.isCustomPage);
      const pageData = { ...res.page_data };

      Object.keys(pageData).forEach((key) => {
        methods.setValue(key, pageData[key]);
      });

      setInitPageData(pageData);
    } catch (error) {
      toast.error(error.response);
      navigate(`/${domainName}`);
    }
  };

  const getFileAnalysis = async () => {
    try {
      const res = await fileExtensionApi.getAllExt();
      setFileExtension(res);
    } catch (error) {
      toast.error(error.response);
    }
  };

  const checkIfAllowedExtension = (key) => {
    return fileExtension.find((e) => key.includes(e.name));
  };

  const handlePrevious = (previousKey, key) => {
    return previousKey ? `${previousKey}.${key}` : key;
  };

  const getRemovableKeys = (values, previousKey) => {
    const removableKeys = [];

    _.forOwn(values, (value, key) => {
      if (checkIfAllowedExtension(key)) {
        removableKeys.push(handlePrevious(previousKey, key));
      }

      // Object
      if (typeof value === "object" && !Array.isArray(value)) {
        const res = getRemovableKeys(value, handlePrevious(previousKey, key));

        res.forEach((re) => {
          removableKeys.push(handlePrevious(previousKey, re));
        });
      }
      // Array
      else if (typeof value === "object" && Array.isArray(value)) {
        value.forEach((elem, elemIndex) => {
          if (typeof elem === "object" && !Array.isArray(elem)) {
            const res = getRemovableKeys(
              elem,
              previousKey ? `${previousKey}.${key}.${elemIndex}` : `${key}.${elemIndex}`
            );
            res.forEach((re) => {
              // Before
              // removableKeys.push(handlePrevious(previousKey, re));
              // After
              removableKeys.push(re);
            });
          }
        });
      }
    });

    return removableKeys;
  };

  const isThereEmptyFields = () => {
    let isEmpty = false;
    const fileShouldBeTrueInArray = deepMap(pageInfo, (value) =>
      value instanceof File ? "FILE" : value
    );
    getObjectPaths(fileShouldBeTrueInArray).map((path) => {
      const value = _.get(fileShouldBeTrueInArray, path);
      if (typeof value === "boolean" || typeof value === "number") return;
      if (path.split(".")[path.split(".").length - 1] === REF_ID) return;
      if (_.isEmpty(value))
        throw {
          response: {
            data: {
              error: [
                {
                  context: {
                    label: path,
                  },
                  message: "Not Allowed to be empty",
                },
              ],
            },
          },
        };
    });
    return isEmpty;
  };

  const handleFileSubmission = async (pageId, File) => {
    const formData = new FormData();
    formData.append("image", File);

    try {
      const option = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressFile(percentage);
        },
      };
      const result = await pageApi.addImage(pageId, formData, option);
      return result;
    } catch (error) {
      toast.error(error?.response?.data?.error || JSON.stringify(error));
    } finally {
      setProgressFile(null);
    }
  };

  const uploadAllFiles = async (pageId, values) => {
    try {
      let newValues = { ...values };
      const allFilesPaths = getAllThePathForFile(newValues);
      if (allFilesPaths.length > 0) {
        await asyncMap(allFilesPaths, async (path, index) => {
          const res = await handleFileSubmission(pageId, _.get(newValues, path));
          _.set(newValues, path, res);
        });
        await pageApi.updatePageById(pageId, newValues);
      }
    } catch (error) {
      let errorMessage = error?.response?.data?.error || error;
      if (Array.isArray(errorMessage)) {
        errorMessage.map((e) => {
          methods.setError(e.context.label, { message: e.message });
        });
        errorMessage = errorMessage.map((e) => `${e.context.label} ${e.message}`);
        toast.error(`Error: ${errorMessage}`);
      } else toast.error(`Error: ${errorMessage}`);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    methods.clearErrors();

    // const newDataWithoutImage = await deepMap(pageInfo, (value) => {
    //   return value instanceof File ? "" : value;
    // });

    const newDataWithoutImage = replaceInstanceFileWithEmptyArray(pageInfo);

    try {
      isThereEmptyFields();
      const res = await pageApi.updatePageById(pageId, newDataWithoutImage);
      await uploadAllFiles(pageId, pageInfo);
      getPageInfo();
      toast.success("update successfully");
    } catch (error) {
      let errorMessage = error?.response?.data?.error || error;
      if (Array.isArray(errorMessage)) {
        errorMessage.map((e) => {
          methods.setError(e.context.label, { message: e.message });
        });
        errorMessage = errorMessage.map((e) => `${e.context.label} ${e.message}`);
        toast.error(`Error: ${errorMessage}`);
      } else toast.error(`Error: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  const removeIndexFromArray = (registerParams) => {
    const values = methods.getValues();
    const item = _.get(values, registerParams);
    if (item !== undefined) {
      const pathArr = registerParams.split(".");
      const lastItem = parseInt(pathArr.pop());
      const newPath = pathArr.join(".");

      _.update(values, newPath, (data) => {
        if (Array.isArray(data)) {
          data.splice(lastItem, 1);
        }
        return data;
      });

      const newPathValue = _.get(values, newPath);
      updatePageInfo(newPath, newPathValue);
    }
  };

  const updatePageInfo = (path, value) => {
    methods.setValue(path, value);
  };

  const getPageInfoByPath = (path) => {
    return _.get(pageInfo, path);
  };

  const onDeletePage = async () => {
    setDeleteLoading(true);
    try {
      const res = await pageApi.deleteCustomPage(pageId);
      toast.success(`Page is deleted it successfully`);
      navigate(-1);
    } catch (error) {
      toast.error(`Error: ${error.response.data.error}`);
    } finally {
      setDeleteLoading(false);
    }
  };

  const resetPageInfo = () => {
    Object.keys(initPageData).forEach((key) => {
      methods.setValue(key, initPageData[key]);
    });
  };

  useEffect(() => {
    getPageInfo();
    getFileAnalysis();
  }, []);

  const isUpdatedIt = useMemo(() => {
    if (!initPageData || !pageInfo) return;
    return _.isEqual(initPageData, pageInfo);
  }, [initPageData, pageInfo]);

  useEffect(() => {
    if (isUpdatedIt) return;
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [isUpdatedIt]);

  return pageInfo && initPageData && fileExtension ? (
    <DashboardLayout>
      <DashboardNavbar />

      {isCustomPage && (
        <MDBox mt={4} mb={1} onClick={onDeletePage}>
          <MDButton disabled={deleteLoading} variant="gradient" color="error">
            Delete Page
            {deleteLoading && (
              <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
            )}
          </MDButton>
        </MDBox>
      )}

      <MDBox py={3} component="form" role="form">
        <FormProvider {...methods}>
          <PageFelidsObject
            data={pageInfo}
            register={methods.register}
            errors={methods.formState.errors}
            removeIndexFromArray={removeIndexFromArray}
            updatePageInfo={updatePageInfo}
            getPageInfoByPath={getPageInfoByPath}
            checkIfAllowedExtension={checkIfAllowedExtension}
            isAllowedToUploadFile={true}
          />
        </FormProvider>

        <MDBox mt={4} mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => onSubmit()}
            disabled={isUpdatedIt}
          >
            Submit
            {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
          </MDButton>
          <MDButton variant="gradient" color="error" onClick={resetPageInfo} disabled={isUpdatedIt}>
            Discard Changes
            {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
          </MDButton>
        </MDBox>
      </MDBox>
      <CircularProgressWithLabel loading={loading} value={progressFile} />
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}
