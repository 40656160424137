/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Card, CircularProgress, Stack, Tab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { TabPanel, TabContext, TabList } from "@mui/lab";

import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ImageResize from "quill-image-resize-module-react";

import blogApi from "api/blog";
import _, { set } from "lodash";

import { useMaterialUIController } from "context";

import MDButton from "components/MDButton";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";

Quill.register("modules/imageResize", ImageResize);

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

export default function Blogs() {
  const { blogId, domainName } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [blogInfo, setBlogInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedAuthorFile, setSelectedAuthorFile] = useState(null);
  const [value, setValue] = useState("");
  const [tabValue, setTabValue] = useState("1");
  const [progressFile, setProgressFile] = useState(null);

  const newQuill = useRef();
  const inputRef = useRef();
  const inputAuthorRef = useRef();

  const onProgressFile = () => {
    const option = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);
        setProgressFile(percentage);
      },
    };
    return option;
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  var mongoObjectId = function () {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
      timestamp +
      "xxxxxxxxxxxxxxxx"
        .replace(/[x]/g, function () {
          return ((Math.random() * 16) | 0).toString(16);
        })
        .toLowerCase()
    );
  };

  const quillGetHTML = (inputDelta) => {
    var tempCont = document.createElement("div");
    new Quill(tempCont).setContents(inputDelta);
    return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
  };

  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleUploadBlogImages = async (delta, blogId) => {
    let newDelta = { ...delta };

    for (const index in newDelta.ops) {
      if (newDelta.ops[index]?.insert?.image) {
        if (newDelta.ops[index].insert.image.split(",")[0].split(";")[1] === "base64") {
          const fileExtension = newDelta.ops[index].insert.image.split(";")[0].split("/")[1];
          const newFileName = mongoObjectId();
          const file = await dataURLtoFile(
            newDelta.ops[index].insert.image,
            `${newFileName}.${fileExtension}`
          );
          const formData = new FormData();
          formData.append("image", file);
          const option = onProgressFile();
          const imageURL = await blogApi.addImage(blogId, domainName, formData, option);
          newDelta.ops[index].insert.image = imageURL;
        }
      }
    }
    return newDelta;
  };

  const getBlogInfo = async () => {
    try {
      const res = await blogApi.getBlogByID(blogId, domainName);
      setValue(res.content);
      setBlogInfo(res);
    } catch (error) {
      toast.error(error.response);
    }
  };

  const updateBlog = async () => {
    const delta = newQuill.current.unprivilegedEditor.getContents();

    setLoading(true);
    try {
      let imageURL = "";
      if (selectedFile) {
        const formData = new FormData();
        formData.append("image", selectedFile);
        const option = onProgressFile();
        imageURL = await blogApi.addImage(blogId, domainName, formData, option);
      }

      let authorImageURL = "";
      if (selectedAuthorFile) {
        const formData = new FormData();
        formData.append("image", selectedAuthorFile);
        authorImageURL = await blogApi.addImage(blogId, domainName, formData);
      }

      const newDelta = await handleUploadBlogImages(delta, blogId);

      const newHtmlInner = quillGetHTML(newDelta);

      const res = await blogApi.updateBlog(
        blogId,
        {
          title: blogInfo.title,
          slug: blogInfo.slug,
          description: blogInfo.description,
          author: blogInfo.author,
          content: newHtmlInner,
          image: imageURL ? imageURL : blogInfo.image,
          authorImage: authorImageURL ? authorImageURL : blogInfo.authorImage,
        },
        domainName
      );
      getBlogInfo();
      setTabValue("1");
      toast.success("Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setLoading(false);
      setProgressFile(null);
    }
  };

  const deleteBlog = async () => {
    setDeleteLoading(true);
    try {
      const res = await blogApi.deleteBlog(blogId, domainName);
      toast.success("Deleted Successfully");
      navigate(`/domain/${domainName}`);
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const removeChosenFile = () => {
    if (selectedFile) {
      setSelectedFile(null);
    } else if (blogInfo.image) {
      setBlogInfo((old) => ({ ...old, image: "" }));
    }
  };
  const removeChosenAuthorFile = () => {
    if (selectedAuthorFile) {
      setSelectedAuthorFile(null);
    } else if (blogInfo.image) {
      setBlogInfo((old) => ({ ...old, authorImage: "" }));
    }
  };
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleAuthorFileChange = (e) => {
    setSelectedAuthorFile(e.target.files[0]);
  };
  const uploadFileHandler = () => {
    inputRef.current.click();
  };
  const uploadAuthorFileHandler = () => {
    inputAuthorRef.current.click();
  };

  useEffect(() => {
    getBlogInfo();
  }, []);

  useEffect(() => {
    if (tabValue === "1") return;
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [tabValue]);

  return blogInfo ? (
    <DashboardLayout>
      <DashboardNavbar />
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="View" value="1" />
            <Tab label="Edit" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <MDBox minHeight="70vh">
            {blogInfo.image !== "" && blogInfo.image && (
              <img src={blogInfo.image} width="100%" height="300" style={{ objectFit: "cover" }} />
            )}
            <MDTypography variant="button" fontSize={22}>
              {blogInfo.title}
            </MDTypography>
            <br />
            <MDTypography variant="button" fontSize={18}>
              {blogInfo.description}
            </MDTypography>
            <div style={{ width: "80%", minHeight: 100, color: darkMode && "white" }}>
              <ReactQuill value={value} readOnly={true} theme="bubble" />
            </div>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                sx={{ marginY: 3, width: 100 }}
                onClick={deleteBlog}
              >
                delete
                {deleteLoading && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </TabPanel>
        <TabPanel value="2">
          <MDBox minHeight="70vh">
            {(blogInfo.image || selectedFile) && (
              <img
                src={selectedFile ? URL.createObjectURL(selectedFile) : blogInfo.image}
                width="100%"
                height="300"
                style={{ objectFit: "cover" }}
              />
            )}

            <input
              type={"file"}
              name="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept={"image/*"}
              ref={inputRef}
            />

            <input
              type={"file"}
              name="file"
              onChange={handleAuthorFileChange}
              style={{ display: "none" }}
              accept={"image/*"}
              ref={inputAuthorRef}
            />

            <Stack direction="row" spacing={"auto"} alignItems="center">
              <MDButton
                variant="gradient"
                color={blogInfo.image || selectedFile ? "error" : "info"}
                sx={{ marginY: 3, width: 160 }}
                onClick={() =>
                  blogInfo.image || selectedFile ? removeChosenFile() : uploadFileHandler()
                }
              >
                {blogInfo.image || selectedFile ? "Remove Image" : "Upload Image"}
              </MDButton>
            </Stack>

            <Stack spacing={3}>
              <Stack direction="row" spacing={3}>
                <MDInput
                  label={"Author"}
                  value={blogInfo.author}
                  fullWidth
                  onChange={(e) => setBlogInfo((old) => ({ ...old, author: e.target.value }))}
                />
                <Avatar
                  alt={blogInfo.author}
                  src={
                    selectedAuthorFile
                      ? URL.createObjectURL(selectedAuthorFile)
                      : blogInfo.authorImage
                  }
                />
                <MDButton
                  variant="gradient"
                  color={selectedAuthorFile || blogInfo.authorImage ? "error" : "info"}
                  sx={{ marginY: 3, width: 160 }}
                  onClick={() =>
                    selectedAuthorFile || blogInfo.authorImage
                      ? removeChosenAuthorFile()
                      : uploadAuthorFileHandler()
                  }
                >
                  {selectedAuthorFile || blogInfo.authorImage ? "Remove Avatar" : "Upload Avatar"}
                </MDButton>
              </Stack>

              <MDInput
                label={"Title"}
                value={blogInfo.title}
                fullWidth
                multiline={true}
                onChange={(e) =>
                  setBlogInfo((old) => ({
                    ...old,
                    title: e.target.value,
                    slug: e.target.value.replace(/\s/g, "-"),
                  }))
                }
              />

              <MDInput
                label={"Slug"}
                value={blogInfo.slug}
                fullWidth
                multiline={true}
                onChange={(e) =>
                  setBlogInfo((old) => ({ ...old, slug: e.target.value.replace(/\s/g, "-") }))
                }
              />

              <MDInput
                label={"Description"}
                value={blogInfo.description}
                fullWidth
                multiline={true}
                onChange={(e) => setBlogInfo((old) => ({ ...old, description: e.target.value }))}
              />

              <div style={{ width: "100%", minHeight: 100, color: darkMode && "white" }}>
                <ReactQuill
                  ref={newQuill}
                  modules={modules}
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  placeholder="Content goes here..."
                />
              </div>
            </Stack>

            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              sx={{ marginY: 3 }}
              onClick={() => updateBlog()}
            >
              Save
              {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
            </MDButton>
          </MDBox>
        </TabPanel>
      </TabContext>
      <CircularProgressWithLabel value={progressFile} loading={loading} />
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}
