import AxiosInstance from "./AxiosInstance";

const auth = {
  getAllMessages: async (domainName, formsKey) => {
    const formKeyStr = formsKey ? `&key=${formsKey}` : "";
    const res = await AxiosInstance.get(`contact-us/domain?domainName=${domainName}${formKeyStr}`);
    return res.data;
  },
  getMessage: async (messageId) => {
    const res = await AxiosInstance.get(`contact-us/${messageId}`);
    return res.data;
  },
  deleteMessage: async (messageId) => {
    const res = await AxiosInstance.delete(`contact-us/${messageId}`);
    return res.data;
  },
};

export default auth;
