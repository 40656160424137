/* eslint-disable */

import React, { useState, useCallback } from "react";
import { Card, CircularProgress, Collapse, Dialog, Stack } from "@mui/material";
import PropTypes from "prop-types";
import _ from "lodash";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PageInput from "./PageInput";
import PageFelidsObject from "./PageFelidsObject";
import MDButton from "components/MDButton";
import splitSpace from "utils/splitSpace";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import getObjectPaths from "utils/getObjectPaths";
import { useForm } from "react-hook-form";
import { useMaterialUIController } from "context";
import deepMap from "utils/deepMap";
import { IMAGE_WIDTH } from "utils/constant";
import { IMAGE_HEIGHT } from "utils/constant";

function PageFelidsArray({
  previousKey,
  keyName,
  data,
  register,
  removeIndexFromArray,
  errors,
  isFile,
  getPageInfoByPath,
  updatePageInfo,
  checkIfAllowedExtension,
  isAllowedToUploadFile,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [expanded, setExpanded] = useState(false);
  const [expandedArray, setExpandedArray] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [changeOrder, setChangeOrder] = useState(false);

  const newMethods = useForm();
  const newArrayData = newMethods.watch();

  const handleExpandClick = () => {
    setExpanded((pldExpand) => !pldExpand);
  };

  const handleChangeOrderClick = () => {
    setChangeOrder((oldOrder) => !oldOrder);
  };

  const getExpandedArray = (index) => {
    return !!expandedArray.find((e) => e === index);
  };

  const handleExpandArrayClick = (index) => {
    if (!getExpandedArray(index)) {
      setExpandedArray((pldExpand) => [...pldExpand, index]);
    } else {
      setExpandedArray((pldExpand) => pldExpand.filter((e) => e !== index));
    }
  };

  const keyFunc = (arrayIndex) => {
    const path = !previousKey
      ? `${keyName}.${arrayIndex}`
      : `${previousKey}.${keyName}.${arrayIndex}`;
    return path;
  };

  const removeElementFromArray = async (arrayIndex) => {
    removeIndexFromArray(keyFunc(arrayIndex));
  };

  const addNewElementToArray = async () => {
    updatePageInfo(keyFunc(data.length), newArrayData);
    handleClose();
  };

  const handleClickOpen = () => {
    const needToOpenDialog = resetArrayData();
    if (needToOpenDialog) setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getArrayInfo = (path) => {
    return _.get(newArrayData, path);
  };

  const updateArrayInfo = (path, value) => {
    newMethods.setValue(path, value);
  };

  const resetArrayData = () => {
    if (typeof data === "object" && typeof data[data.length - 1] === "object") {
      const newData = { ...data[data.length - 1] };
      const fileShouldBeTrueInArray = deepMap(newData, (value) =>
        value instanceof File ? "" : value
      );
      getObjectPaths(fileShouldBeTrueInArray).map((path) => {
        const value = _.get(fileShouldBeTrueInArray, path);
        let newValue;
        if (path.endsWith(IMAGE_WIDTH) || path.endsWith(IMAGE_HEIGHT)) {
          newValue = value;
        } else {
          newValue =
            typeof value === "boolean"
              ? false
              : typeof value === "string"
              ? ""
              : typeof value === "number"
              ? 0
              : "";
        }
        newMethods.setValue(path, newValue);
      });
      return true;
    } else {
      const value = data[data.length - 1];
      const newValue =
        typeof value === "boolean"
          ? false
          : typeof value === "string"
          ? ""
          : typeof value === "number"
          ? 0
          : "";
      updatePageInfo(keyFunc(data.length), newValue);
      return false;
    }
  };

  const isThereEmptyFields = useCallback(() => {
    if (!newArrayData || !openDialog) return;
    let isEmpty = false;
    const fileShouldBeTrueInArray = deepMap(newArrayData, (value) =>
      value instanceof File ? URL.createObjectURL(value) : value
    );
    getObjectPaths(fileShouldBeTrueInArray).map((path) => {
      const value = _.get(fileShouldBeTrueInArray, path);
      if (typeof value === "number" || typeof value === "boolean") return;
      if (_.isEmpty(value)) isEmpty = true;
    });
    return isEmpty;
  }, [newArrayData]);

  const moveItemUpOrDown = (index, IsUp = true) => {
    const path = !previousKey ? `${keyName}` : `${previousKey}.${keyName}`;

    const array = [...data];

    const sourceIndex = index;
    const targetIndex = IsUp ? index - 1 : index + 1;

    const removed1 = array.splice(sourceIndex, 1);
    const removed2 = array.splice(targetIndex - 1, 1);

    array.splice(sourceIndex, 0, removed2[0]);
    array.splice(targetIndex, 0, removed1[0]);

    updatePageInfo(path, array);
  };

  return (
    <MDBox py={0} mt={3} key={`${previousKey}.${keyName}`}>
      <Card sx={{ userSelect: "none" }}>
        <MDBox
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent={"space-between"}
          onClick={handleExpandClick}
          sx={{ marginY: 2, marginX: 3, cursor: "pointer" }}
          alignItems="center"
        >
          <MDTypography
            component="a"
            variant="caption"
            color="text"
            fontWeight="medium"
            fontSize={15}
          >
            {splitSpace(keyName)}
          </MDTypography>
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </MDBox>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <MDBox mx={2}>
            {data.map((e, i) => (
              <Card sx={{ marginBottom: 2 }} key={i}>
                <MDBox
                  display="flex"
                  flex={1}
                  flexDirection="row"
                  justifyContent={"space-between"}
                  onClick={() => !changeOrder && handleExpandArrayClick(i + 1)}
                  sx={{ marginY: 2, marginX: 3, cursor: "pointer" }}
                  alignItems="center"
                >
                  <MDTypography
                    component="a"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    fontSize={15}
                  >
                    {splitSpace(keyName)} {i + 1}
                  </MDTypography>
                  <MDBox>
                    {!changeOrder ? (
                      getExpandedArray(i + 1) ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )
                    ) : data.length > 1 ? (
                      <Stack spacing={2} direction="row">
                        {i === 0 ? (
                          <MDButton
                            variant="gradient"
                            color="success"
                            onClick={() => moveItemUpOrDown(i, false)}
                          >
                            Move Down
                            <KeyboardArrowDown />
                          </MDButton>
                        ) : data.length - 1 === i ? (
                          <>
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => moveItemUpOrDown(i)}
                            >
                              Move Up
                              <KeyboardArrowUp />
                            </MDButton>
                          </>
                        ) : (
                          <>
                            <MDButton
                              variant="gradient"
                              color="success"
                              onClick={() => moveItemUpOrDown(i, false)}
                            >
                              Move Down
                              <KeyboardArrowDown />
                            </MDButton>
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => moveItemUpOrDown(i)}
                            >
                              Move Up
                              <KeyboardArrowUp />
                            </MDButton>
                          </>
                        )}
                      </Stack>
                    ) : null}
                  </MDBox>
                </MDBox>
                <Collapse in={getExpandedArray(i + 1)} timeout="auto" unmountOnExit>
                  {typeof e === "object" && !(e instanceof File) ? (
                    <MDBox key={keyFunc(i)} mt={2}>
                      <Card sx={{ paddingX: 5, paddingBottom: 3 }}>
                        <PageFelidsObject
                          data={e}
                          previousKey={keyFunc(i)}
                          isArray={false}
                          register={register}
                          removeIndexFromArray={removeIndexFromArray}
                          errors={errors}
                          getPageInfoByPath={getPageInfoByPath}
                          updatePageInfo={updatePageInfo}
                          checkIfAllowedExtension={checkIfAllowedExtension}
                          isAllowedToUploadFile={isAllowedToUploadFile}
                        />
                        {data.length > 1 && (
                          <MDButton
                            variant="gradient"
                            color="error"
                            onClick={() => removeElementFromArray(i)}
                            sx={{ width: 100, alignSelf: "right" }}
                          >
                            Remove
                          </MDButton>
                        )}
                      </Card>
                    </MDBox>
                  ) : (
                    <MDBox sx={{ marginX: 4 }}>
                      <PageInput
                        value={e}
                        registerParams={keyFunc(i)}
                        isArray={true}
                        register={register}
                        removeIndexFromArray={removeIndexFromArray}
                        errors={errors}
                        key={keyFunc(i)}
                        isFile={isFile}
                        getPageInfoByPath={getPageInfoByPath}
                        updatePageInfo={updatePageInfo}
                        isOnlyItem={data.length > 1}
                        isAllowedToUploadFile={isAllowedToUploadFile}
                      />
                    </MDBox>
                  )}
                </Collapse>
              </Card>
            ))}
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleClickOpen}
              sx={{ mt: 5, width: 200, alignSelf: "center", mb: 5, marginY: 2, marginX: 3 }}
            >
              Add
            </MDButton>
            {data.length > 1 && (
              <MDButton
                variant="gradient"
                color="primary"
                onClick={handleChangeOrderClick}
                sx={{ mt: 5, width: 200, alignSelf: "center", mb: 5, marginY: 2, marginX: 3 }}
              >
                {changeOrder ? "Cancel Change Order" : "Change Order"}
              </MDButton>
            )}
          </MDBox>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            {openDialog && (
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card
                  sx={{
                    paddingX: 5,
                    paddingBottom: 3,
                    margin: 2,
                    display: "flex",
                  }}
                >
                  <MDBox>
                    <MDTypography variant="h4" sx={{ marginTop: 3 }}>
                      ADD NEW TO {splitSpace(keyName)}
                    </MDTypography>
                  </MDBox>
                  <PageFelidsObject
                    data={newArrayData}
                    isArray={false}
                    register={newMethods.register}
                    removeIndexFromArray={removeIndexFromArray}
                    errors={newMethods.formState.errors}
                    getPageInfoByPath={getArrayInfo}
                    updatePageInfo={updateArrayInfo}
                    checkIfAllowedExtension={checkIfAllowedExtension}
                    isAllowedToUploadFile={true}
                  />
                  <Stack
                    direction="row"
                    display="flex"
                    justifyContent="space-between"
                    marginTop={2}
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={addNewElementToArray}
                      sx={{ mt: 5, width: 200, alignSelf: "center", mb: 5, marginY: 2, marginX: 3 }}
                      disabled={isThereEmptyFields()}
                    >
                      Save
                    </MDButton>
                    <MDButton
                      variant="gradient"
                      color="error"
                      onClick={handleClose}
                      sx={{ mt: 5, width: 200, alignSelf: "center", mb: 5, marginY: 2, marginX: 3 }}
                    >
                      Cancel
                    </MDButton>
                  </Stack>
                </Card>
              </MDBox>
            )}
          </Dialog>
        </Collapse>
      </Card>
    </MDBox>
  );
}

PageFelidsArray.defaultProps = {
  previousKey: "",
};

PageFelidsArray.propTypes = {
  previousKey: PropTypes.any,
  keyName: PropTypes.any,
  data: PropTypes.any,
  register: PropTypes.any,
  checkIfAllowedExtension: PropTypes.any.isRequired,
};

export default PageFelidsArray;
