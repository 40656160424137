import AxiosInstance from "./AxiosInstance";

const config = {
  getByName: async (name) => {
    const res = await AxiosInstance.get(`/config/name?name=${name}`);
    return res.data;
  },
};

export default config;
