import AxiosInstance from "./AxiosInstance";

const subscription = {
  getByDomainName: async (domainName) => {
    const res = await AxiosInstance.get(`/subscription/domain?domainName=${domainName}`);
    return res.data;
  },
};

export default subscription;
