import AxiosInstance from "./AxiosInstance";

const archive = {
  get: async (domainName, page, fileName) => {
    const res = await AxiosInstance.get(
      `/archive/domain?domainName=${domainName}&page=${page}&perPage=${10}&fileName=${fileName}`
    );
    return res.data;
  },
};

export default archive;
