/* eslint-disable */

function getAllThePathForFile(obj, path = "") {
  const paths = [];

  for (const [key, val] of Object.entries(obj)) {
    const currentPath = path ? `${path}.${key}` : key;
    if (val instanceof File) {
      paths.push(currentPath);
    } else if (val instanceof Object) {
      paths.push(...getAllThePathForFile(val, currentPath));
    }
  }

  return paths;
}
export default getAllThePathForFile;
