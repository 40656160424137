/* eslint-disable */

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import MDInput from "components/MDInput";

import ownerApi from "api/owner";
import { useOwnerStore } from "store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress, Modal, Stack } from "@mui/material";

function ProfileInfoCard({ title, description, info, action, shadow, isEditMode }) {
  const navigate = useNavigate();

  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    getValues,
  } = useForm();

  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);

  const handleShowPassword = () => setShowUpdatePassword((old) => !old);

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  const setOwnerData = useOwnerStore((state) => state.setOwnerData);
  const getMyInfo = async () => {
    try {
      const res = await ownerApi.getOwnerByToken();
      setOwnerData(res);
    } catch (error) {
      navigate("/authentication/sign-in");
    }
  };

  const onSubmit = async (data) => {
    setLoadingData(true);
    const newData = { ...data };
    delete newData["password"];
    delete newData["confirm_password"];
    try {
      const res = await ownerApi.updateOwnerInfo(newData);
      await getMyInfo();
      action.onclick();
      toast.success("update successfully");
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
      // setError("login", { type: "login", message: error.response.data.message });
    } finally {
      setLoadingData(false);
    }
  };

  const updatePassword = async () => {
    setLoadingPassword(true);
    const value = getValues();
    let data = {};
    data["password"] = value["password"];
    data["confirm_password"] = value["confirm_password"];

    try {
      const res = await ownerApi.updateOwnerPassword(data);
      handleShowPassword();
      toast.success("update password successfully");
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoadingPassword(false);
    }
  };

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {label}: &nbsp;
          </MDTypography>
          {!isEditMode && (
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{values[key]}
            </MDTypography>
          )}
        </Grid>
        {isEditMode && (
          <Grid item xs={6} md={10} xl={10} sx={{ display: "flex" }}>
            <MDInput
              type="email"
              label={label}
              fullWidth
              register={register(label, { required: true })}
            />
          </Grid>
        )}
      </Grid>
    </MDBox>
  ));

  useEffect(() => {
    labels.forEach((label, key) => {
      setValue(label, values[key]);
    });
  }, []);

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="secondary">
          <Tooltip
            title={(isEditMode ? "Close " : "Edit ") + action.tooltip}
            placement="top"
            onClick={() => action.onclick()}
          >
            <Icon>{isEditMode ? "close" : "edit"}</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {renderItems}
          {showUpdatePassword && (
            <Stack spacing={2} marginTop={2}>
              <MDBox>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {"password"}: &nbsp;
                </MDTypography>
                <MDInput
                  type="password"
                  label={"password"}
                  fullWidth
                  register={register("password", { required: true })}
                  sx={{ marginTop: 1 }}
                />
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {"confirm password"}: &nbsp;
                </MDTypography>
                <MDInput
                  type="password"
                  label={"confirm password"}
                  fullWidth
                  register={register("confirm_password", { required: true })}
                  sx={{ marginTop: 1 }}
                />
              </MDBox>
            </Stack>
          )}
          {!showUpdatePassword && !isEditMode && (
            <MDButton
              variant="gradient"
              color="info"
              sx={{ marginTop: 2 }}
              onClick={handleShowPassword}
            >
              Update Password
            </MDButton>
          )}

          {showUpdatePassword && !isEditMode && (
            <Stack direction="row" spacing={2} mt={2}>
              <MDButton variant="gradient" color="success" onClick={updatePassword}>
                Update Password
                {loadingPassword && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={handleShowPassword}>
                Cancel
              </MDButton>
            </Stack>
          )}

          {isEditMode && (
            <MDBox
              mt={2}
              mb={1}
              alignItems="center"
              alignSelf="center"
              onClick={handleSubmit(onSubmit)}
            >
              <MDButton variant="gradient" color="success">
                Update
                {loadingData && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    onclick: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  isEditMode: PropTypes.bool.isRequired,
};

export default ProfileInfoCard;
