import AxiosInstance from "./AxiosInstance";

const domain = {
  getMyDomains: async () => {
    const res = await AxiosInstance.get("/domain/me");
    return res.data;
  },

  getDomainInfo: async (domainName) => {
    const res = await AxiosInstance.get(`/domain/me/${domainName}`);
    return res.data;
  },

  updateDomainMeta: async (domainId, body) => {
    const res = await AxiosInstance.put(`domain/meta/${domainId}`, body);
    return res.data;
  },
};

export default domain;
