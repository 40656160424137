/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Card, CircularProgress, Stack, Tab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { TabPanel, TabContext, TabList } from "@mui/lab";

import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ImageResize from "quill-image-resize-module-react";

import blogApi from "api/blog";
import _, { set } from "lodash";

import { useMaterialUIController } from "context";

import MDButton from "components/MDButton";

import ReactQuill, { Quill } from "react-quill";
import { ImageDrop } from "quill-image-drop-module";
import "react-quill/dist/quill.snow.css";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
  imageDrop: true,
};

export default function CreateBlog() {
  const { domainName } = useParams();

  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [blogInfo, setBlogInfo] = useState({
    title: "",
    description: "",
    content: "",
    slug: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedAuthorFile, setSelectedAuthorFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (value) => {
    setBlogInfo((old) => ({ ...old, content: value }));
  };

  const newQuill = useRef();
  const inputRef = useRef();
  const inputAuthorRef = useRef();

  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  var mongoObjectId = function () {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
      timestamp +
      "xxxxxxxxxxxxxxxx"
        .replace(/[x]/g, function () {
          return ((Math.random() * 16) | 0).toString(16);
        })
        .toLowerCase()
    );
  };

  const quillGetHTML = (inputDelta) => {
    var tempCont = document.createElement("div");
    new Quill(tempCont).setContents(inputDelta);
    return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
  };

  const handleUploadBlogImages = async (delta, mongoDbId) => {
    let newDelta = { ...delta };

    for (const index in newDelta.ops) {
      if (newDelta.ops[index]?.insert?.image) {
        const fileExtension = newDelta.ops[index].insert.image.split(";")[0].split("/")[1];
        const newFileName = mongoObjectId();
        const file = await dataURLtoFile(
          newDelta.ops[index].insert.image,
          `${newFileName}.${fileExtension}`
        );
        const formData = new FormData();
        formData.append("image", file);

        const imageURL = await blogApi.addImage(mongoDbId, domainName, formData);
        newDelta.ops[index].insert.image = imageURL;
      }
    }
    return newDelta;
  };

  const createBlogHandler = async () => {
    if (!blogInfo.title || !blogInfo.slug) {
      toast.error("Title and slug is required");
      return;
    }

    setLoading(true);

    const mongoDbId = mongoObjectId();

    const delta = newQuill.current.unprivilegedEditor.getContents();

    try {
      const res = await blogApi.createBlog(domainName, {
        ...blogInfo,
        content: "",
        _id: mongoDbId,
      });

      let imageURL = "";
      if (selectedFile) {
        const formData = new FormData();
        formData.append("image", selectedFile);
        imageURL = await blogApi.addImage(mongoDbId, domainName, formData);
      }

      let authorImageURL = "";
      if (selectedAuthorFile) {
        const formData = new FormData();
        formData.append("image", selectedAuthorFile);
        authorImageURL = await blogApi.addImage(mongoDbId, domainName, formData);
      }

      const newDelta = await handleUploadBlogImages(delta, mongoDbId);

      const newHtmlInner = quillGetHTML(newDelta);

      const updateBlog = await blogApi.updateBlog(
        mongoDbId,
        { content: newHtmlInner, image: imageURL, authorImage: authorImageURL },
        domainName
      );

      navigate(`/domain/${domainName}/blog/${res._id}`);
      toast.success("Created Successfully");
    } catch (error) {
      toast.error(error?.response?.data?.error || error);
    } finally {
      setLoading(false);
    }
  };

  const removeChosenFile = () => setSelectedFile(null);
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const removeChosenAuthorFile = () => setSelectedAuthorFile(null);
  const handleAuthorFileChange = (e) => {
    setSelectedAuthorFile(e.target.files[0]);
  };

  const uploadFileHandler = () => {
    inputRef.current.click();
  };
  const uploadAuthorFileHandler = () => {
    inputAuthorRef.current.click();
  };

  return blogInfo ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox minHeight="80vh">
        {selectedFile && (
          <img
            src={URL.createObjectURL(selectedFile)}
            width="100%"
            height="300"
            style={{ objectFit: "cover" }}
          />
        )}

        <input
          type={"file"}
          name="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept={"image/*"}
          ref={inputRef}
        />

        <input
          type={"file"}
          name="file"
          onChange={handleAuthorFileChange}
          style={{ display: "none" }}
          accept={"image/*"}
          ref={inputAuthorRef}
        />

        <Stack direction="row" spacing={"auto"} alignItems="center">
          <MDButton
            variant="gradient"
            color={selectedFile ? "error" : "info"}
            sx={{ marginY: 3, width: 160 }}
            onClick={() => (selectedFile ? removeChosenFile() : uploadFileHandler())}
          >
            {selectedFile ? "Remove Image" : "Upload Image"}
          </MDButton>
        </Stack>

        <Stack spacing={3}>
          <Stack dir="row">
            <Stack direction="row" spacing={"auto"} alignItems="center">
              <MDInput
                label={"Author"}
                value={blogInfo.author}
                onChange={(e) => setBlogInfo((old) => ({ ...old, author: e.target.value }))}
              />
              <Avatar
                alt={blogInfo.author}
                src={
                  selectedAuthorFile
                    ? URL.createObjectURL(selectedAuthorFile)
                    : blogInfo.authorImage
                }
              />
              <MDButton
                variant="gradient"
                color={selectedAuthorFile ? "error" : "info"}
                sx={{ marginY: 3, width: 160 }}
                onClick={() =>
                  selectedAuthorFile ? removeChosenAuthorFile() : uploadAuthorFileHandler()
                }
              >
                {selectedAuthorFile ? "Remove Avatar" : "Upload Avatar"}
              </MDButton>
            </Stack>
          </Stack>

          <MDInput
            label={"Title"}
            value={blogInfo.title}
            fullWidth
            multiline={true}
            onChange={(e) =>
              setBlogInfo((old) => ({
                ...old,
                title: e.target.value,
                slug: e.target.value.replace(/\s/g, "-"),
              }))
            }
          />

          <MDInput
            label={"Slug"}
            value={blogInfo.slug}
            fullWidth
            multiline={true}
            onChange={(e) =>
              setBlogInfo((old) => ({ ...old, slug: e.target.value.replace(/\s/g, "-") }))
            }
          />

          <MDInput
            label={"Description"}
            value={blogInfo.description}
            fullWidth
            multiline={true}
            onChange={(e) => setBlogInfo((old) => ({ ...old, description: e.target.value }))}
          />

          <div style={{ width: "100%", minHeight: 100, color: darkMode && "white" }}>
            <ReactQuill
              ref={newQuill}
              modules={modules}
              theme="snow"
              value={blogInfo.content}
              onChange={handleChange}
              placeholder="Content goes here..."
            />
          </div>
        </Stack>

        <MDButton
          variant="gradient"
          color="info"
          fullWidth
          sx={{ marginY: 3 }}
          onClick={() => createBlogHandler()}
        >
          Save
          {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
        </MDButton>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}
