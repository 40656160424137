/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import analysisApi from "api/analysis";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import _ from "lodash";
import DataTable from "examples/Tables/DataTable";
import { useMaterialUIController } from "context";
import ExpandCountries from "components/ExpandCountries";

const COLUMNS = [
  { Header: "Day", accessor: "day", align: "left" },
  { Header: "Views", accessor: "view", align: "left" },
  { Header: "Countries", accessor: "country", align: "left" },
];

export default function AnalysisDay() {
  const { pageId, year, month } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pageAnalysis, setPageAnalysis] = useState(null);
  const [pageViewsRow, setPageViewsRow] = useState(null);

  const getPageInfo = async () => {
    try {
      const res = await analysisApi.getAnalysisByDays(pageId, year, month);

      const newPagesAnalysis = {
        datasets: {
          data: [],
          label: "pages",
        },
        labels: [],
      };

      res.data.forEach((e) => {
        newPagesAnalysis.labels.push(e._id);
        newPagesAnalysis.datasets.data.push(e.views);
      });

      const pageViewsRow = [];
      res.data.forEach((e) => {
        pageViewsRow.push({
          day: e._id,
          view: (
            <>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {e.views}
              </MDTypography>
            </>
          ),
          country: <ExpandCountries viewDetails={e.countries} />,
        });
      });

      setPageViewsRow(pageViewsRow);
      setPageAnalysis(newPagesAnalysis);
    } catch (error) {
      toast.error(error.response);
    }
  };

  useEffect(() => {
    getPageInfo();
  }, []);

  return pageAnalysis ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <ReportsLineChart
          color="info"
          title="Daily Visits"
          date="updated just now"
          chart={pageAnalysis}
        />
      </MDBox>

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Days Analysis
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: pageViewsRow }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              canSearch
            />
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}
