/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Box, Card, CircularProgress, Grid } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import { REF, REF_ID, REF_MODAL, REF_SELECTED_ITEM, IMAGE } from "utils/constant";
import blogApi from "api/blog";
import { Masonry } from "@mui/lab";

const SelectedBlogs = ({ path, data, updatePageInfo }) => {
  const { pageId, domainName } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [blogs, setBlogs] = useState(null);

  const getAllBlogs = async () => {
    try {
      const res = await blogApi.getBlogsByDomain(domainName);
      setBlogs(res.blogs);
    } catch (error) {
      navigate(`/domain/${domainName}/page/${pageId}`);
    }
  };

  const checkIfIdExists = (refId) => {
    return Array.isArray(data[REF][REF_ID])
      ? data[REF][REF_ID].find((e) => e === refId)
      : data[REF][REF_ID] === refId;
  };

  const onAddBlog = (refId) => {
    updatePageInfo(
      `${path}.${REF_ID}`,
      Array.isArray(data[REF][REF_ID]) ? [...data[REF][REF_ID], refId] : refId
    );
  };
  const onRemoveBlog = (refId) => {
    updatePageInfo(
      `${path}.${REF_ID}`,
      Array.isArray(data[REF][REF_ID]) ? data[REF][REF_ID].filter((e) => e !== refId) : ""
    );
  };

  const onClickHandler = (refId) => {
    checkIfIdExists(refId) ? onRemoveBlog(refId) : onAddBlog(refId);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return blogs ? (
    <MDBox sx={{ marginTop: 3, paddingY: 3 }}>
      <Masonry columns={3} spacing={1}>
        {blogs.map((blog) => (
          <Grid item xs={4} height="auto" key={blog._id}>
            <Card sx={{ paddingY: 2, paddingX: 4 }}>
              {blog.image && (
                <img src={blog.image} width="100%" height={100} style={{ objectFit: "cover" }} />
              )}
              <MDTypography sx={{ marginTop: 3 }}>{blog.title}</MDTypography>
              <MDTypography sx={{ marginTop: 3 }}>{blog.description}</MDTypography>
              <MDBox display="flex" justifyContent="space-around" marginTop={3}>
                <Link to={`/domain/${domainName}/blog/${blog._id}`} target="_blank">
                  <MDButton width={100} size="small">
                    Open
                    <OpenInNewIcon />
                  </MDButton>
                </Link>
                {(Array.isArray(data[REF][REF_ID]) || typeof data[REF][REF_ID] === "string") && (
                  <MDButton
                    width={100}
                    size="small"
                    variant="gradient"
                    color={checkIfIdExists(blog._id) ? "error" : "info"}
                    onClick={() => onClickHandler(blog._id)}
                  >
                    {checkIfIdExists(blog._id) ? "REMOVE" : "ADD"}
                  </MDButton>
                )}
              </MDBox>
            </Card>
          </Grid>
        ))}
      </Masonry>
    </MDBox>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "40vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
};

export default SelectedBlogs;
