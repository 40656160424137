import AxiosInstance from "./AxiosInstance";

const auth = {
  getAnalysisByYears: async (pageId) => {
    const res = await AxiosInstance.get(`analysis/page/${pageId}/year`);
    return res.data;
  },
  getAnalysisByMonths: async (pageId, year) => {
    const res = await AxiosInstance.get(`analysis/page/${pageId}/month?year=${year}`);
    return res.data;
  },
  getAnalysisByDays: async (pageId, year, month) => {
    const res = await AxiosInstance.get(`analysis/page/${pageId}/days?year=${year}&month=${month}`);
    return res.data;
  },

  getAnalysisBlogByYears: async (blogId) => {
    const res = await AxiosInstance.get(`analysis/blog/${blogId}/year`);
    return res.data;
  },
  getAnalysisBlogByMonths: async (blogId, year) => {
    const res = await AxiosInstance.get(`analysis/blog/${blogId}/month?year=${year}`);
    return res.data;
  },
  getAnalysisBlogByDays: async (blogId, year, month) => {
    const res = await AxiosInstance.get(`analysis/blog/${blogId}/days?year=${year}&month=${month}`);
    return res.data;
  },
};

export default auth;
