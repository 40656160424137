/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useMaterialUIController } from "context";
import pageApi from "api/page";
import { Card, CircularProgress } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";

const COLUMNS = [
  { Header: "Pages", accessor: "page_route", align: "left" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "Views", accessor: "view", align: "center" },
  { Header: "META", accessor: "meta", align: "center" },
  { Header: "Views Analytics", accessor: "viewAnalytics", align: "center" },
  { Header: "Edit", accessor: "action", align: "center" },
];

const COLUMNS_CUSTOM_PAGES = [
  { Header: "Pages", accessor: "page_route", align: "left" },
  { Header: "Number of pages", accessor: "numberOfPages", align: "center" },
  { Header: "Views", accessor: "view", align: "center" },
  { Header: "View", accessor: "actionShow", align: "center" },
  { Header: "Create", accessor: "action", align: "center" },
];

export default function Page() {
  const { domainName } = useParams();

  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pagesInfo, setPagesInfo] = useState(null);
  const [customPagesInfo, setCustomPagesInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigateToPageMETA = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}/meta`);
  };
  const navigateToPageAnalysis = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}/analysis`);
  };
  const navigateToPage = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}`);
  };
  const navigateToCustomPage = (page_route) => {
    navigate(`/domain/${domainName}/page/custom?route=${page_route}`);
  };
  const navigateToCreateCustomPage = (page_route) => {
    navigate({
      pathname: `/domain/${domainName}/page/create`,
      search: `?route=${page_route}`,
    });
  };

  const getPagesInfo = async () => {
    try {
      const res = await pageApi.getMyPagesByDomain(domainName);

      const newPagesInfo = [];
      res.pagesInfo.pages.map((page) => {
        newPagesInfo.push({
          page_route: domainName + page.page_route,
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.views}
            </MDTypography>
          ),
          meta: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton
                disabled={!res.isMETAActivate}
                color="primary"
                size="small"
                onClick={() => navigateToPageMETA(page._id)}
              >
                META
              </MDButton>
            </MDBox>
          ),
          viewAnalytics: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="info" size="small" onClick={() => navigateToPageAnalysis(page._id)}>
                Analysis
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToPage(page._id)}>
              <MDButton color="success" size="small">
                Edit
              </MDButton>
            </MDBox>
          ),
        });
      });
      setPagesInfo(newPagesInfo);

      const newCustomPagesInfo = [];
      res.customPagesInfo.customPages.map((page) => {
        newCustomPagesInfo.push({
          page_route: domainName + page.route,
          view: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.views}
            </MDTypography>
          ),
          numberOfPages: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.numberOfPages}
            </MDTypography>
          ),
          actionShow: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToCustomPage(page.route)}>
              <MDButton color="info" size="small">
                View
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToCreateCustomPage(page.route)}>
              <MDButton color="success" size="small">
                Create
              </MDButton>
            </MDBox>
          ),
        });
      });
      setCustomPagesInfo(newCustomPagesInfo);

      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getPagesInfo();
  }, []);

  return !loading ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3} minHeight="60vh">
        <Card sx={{ marginTop: 7 }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Pages
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: pagesInfo }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              canSearch
            />
          </MDBox>
        </Card>

        <Card sx={{ marginTop: 7 }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Custom Pages
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS_CUSTOM_PAGES, rows: customPagesInfo }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}
