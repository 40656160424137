/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useNotificationStore } from "store/notificationStore";

function ConfiguratorNotification() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const openNotification = useNotificationStore((store) => store.openNotification);
  const notificationData = useNotificationStore((store) => store.data);
  const isOpen = useNotificationStore((store) => store.isOpen);

  const handleNotificationSidenav = () => openNotification();

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator: isOpen }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Notifications</MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleNotificationSidenav}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        {notificationData &&
          notificationData.map((e) => (
            <MDBox key={e._id}>
              <MDTypography variant="h5">{e.title}</MDTypography>
              <MDTypography variant="h6">{e.body}</MDTypography>
              <Divider />
            </MDBox>
          ))}
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default ConfiguratorNotification;
