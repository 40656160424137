/* eslint-disable */

import _ from "lodash";

const replaceInstanceFileWithEmptyArray = (pageInfo) => {
  // return _.mapValues(pageInfo, (value) => {
  //   if (value instanceof File) {
  //     return "";
  //   } else if (_.isPlainObject(value)) {
  //     return _.mapValues(value, (nestedValue) => {
  //       if (nestedValue instanceof File) {
  //         return "";
  //       } else if (_.isArray(nestedValue)) {
  //         return nestedValue.map((arrayValue) => {
  //           if (arrayValue instanceof File) {
  //             return "";
  //           } else if (_.isPlainObject(arrayValue)) {
  //             return _.mapValues(arrayValue, (deeplyNestedValue) => {
  //               return deeplyNestedValue instanceof File ? "" : deeplyNestedValue;
  //             });
  //           } else {
  //             return arrayValue;
  //           }
  //         });
  //       } else {
  //         return nestedValue;
  //       }
  //     });
  //   } else {
  //     return value;
  //   }
  // });
  return JSON.parse(
    JSON.stringify(pageInfo, (key, value) => {
      if (value instanceof File) {
        return "";
      }
      return value;
    })
  );
};

export default replaceInstanceFileWithEmptyArray;
