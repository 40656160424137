/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useOwnerStore, useDomainStore } from "store";

import ownerApi from "api/owner";
import domainApi from "api/domain";
import notificationApi from "api/notification";
import configApi from "api/config";

// Domain
import DomainSubscription from "layouts/domain/subscription";
import DomainArchive from "layouts/domain/archive";
import DomainMETA from "layouts/domain/meta";
import Domain from "layouts/domain";

// Blogs
import Blogs from "layouts/blog";
import UpdateBlogs from "layouts/blog/update";
import CreateBlog from "layouts/blog/create";
import AnalysisYearBlog from "layouts/blog/analysis/year";
import AnalysisMonthBlog from "layouts/blog/analysis/month";
import AnalysisDayBlog from "layouts/blog/analysis/day";
import BlogMeta from "layouts/blog/update/meta";

// Page
import CreateCustomPage from "layouts/page/custom/create";
import AnalysisMonth from "layouts/page/analysis/month";
import AnalysisYear from "layouts/page/analysis/year";
import AnalysisDay from "layouts/page/analysis/day";
import PageMeta from "layouts/page/meta";
import UpdatePage from "layouts/page/update";
import Page from "layouts/page";

import Messages from "layouts/messages";
import ErrorFallback from "layouts/error";

import { requestPermission, onMessageListener } from "./firebaseInit";
import { useNotificationStore } from "store/notificationStore";
import ConfiguratorNotification from "examples/Configurator/ConfiguratorNotification";
import CustomPages from "layouts/page/custom/view";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const audioRef = useRef(new Audio("/notification-sound.mp3"));

  const ownerStore = useOwnerStore((state) => state.ownerStore);
  const setOwnerStore = useOwnerStore((state) => state.setOwnerStore);
  const ownerData = useOwnerStore((state) => state.ownerData);
  const setOwnerData = useOwnerStore((state) => state.setOwnerData);

  const domainStore = useDomainStore((state) => state.domainStore);
  const setDomainStore = useDomainStore((state) => state.setDomainStore);

  const setNotificationStore = useNotificationStore((state) => state.setNotificationStore);

  const getNotification = async () => {
    try {
      const notification = await notificationApi.getMyNotification();
      setNotificationStore({ number: notification.number, data: notification.data });
    } catch (error) {}
  };

  const getMyInfo = async () => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    try {
      if (token) {
        setOwnerStore(token);
      }
      const res = await ownerApi.getOwnerByToken();
      const resDomain = await domainApi.getMyDomains();
      setDomainStore(resDomain);
      setOwnerData(res);
      requestPermission(res.FCMTokens);
      await getNotification();
    } catch (error) {
      navigate("/authentication/sign-in");
    }
  };

  const getConfigName = async () => {
    const releaseClient = "releaseClient";
    try {
      const value = await configApi.getByName(releaseClient);
      const oldValue = localStorage.getItem(releaseClient);
      localStorage.setItem(releaseClient, value.value);
      if (oldValue !== value.value) window.location.reload(true);
    } catch (error) {}
  };

  onMessageListener(async (payload) => {
    await getNotification();
    toast.info(payload?.notification?.title + payload?.notification?.body);
    audioRef.current.play();
  });

  // useEffect(() => {
  //   if (ownerData) return;
  //   getMyInfo();
  //   if (ownerStore) {
  //     getMyInfo();
  //   } else {
  //     // navigate("/authentication/sign-in");
  //   }
  //   // eslint-disable-next-line
  // }, [ownerStore]);

  useEffect(() => {
    getMyInfo();
  }, [ownerStore]);

  useEffect(() => {
    getConfigName();
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ToastContainer
        bodyStyle={{
          fontSize: 13,
        }}
      />
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {layout === "dashboard" && ownerData && domainStore && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="BaaS"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            <ConfiguratorNotification />
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
          <Route path="/domain/:domainName/page/:pageId/meta" element={<PageMeta />} />
          <Route path="/domain/:domainName/page/:pageId/analysis" element={<AnalysisYear />} />
          <Route
            path="/domain/:domainName/page/:pageId/analysis/:year"
            element={<AnalysisMonth />}
          />
          <Route
            path="/domain/:domainName/page/:pageId/analysis/:year/:month"
            element={<AnalysisDay />}
          />
          <Route path="/domain/:domainName/blog/:blogId/meta" element={<BlogMeta />} />
          <Route path="/domain/:domainName/blog/:blogId/analysis" element={<AnalysisYearBlog />} />
          <Route
            path="/domain/:domainName/blog/:blogId/analysis/:year"
            element={<AnalysisMonthBlog />}
          />
          <Route
            path="/domain/:domainName/blog/:blogId/analysis/:year/:month"
            element={<AnalysisDayBlog />}
          />
          <Route path="/domain/:domainName/page/create" element={<CreateCustomPage />} />
          <Route path="/domain/:domainName/page/custom" element={<CustomPages />} />
          <Route path="/domain/:domainName/page/:pageId" element={<UpdatePage />} />
          <Route path="/domain/:domainName/page" element={<Page />} />
          <Route path="/domain/:domainName/blog/create" element={<CreateBlog />} />
          <Route path="/domain/:domainName/blog/:blogId" element={<UpdateBlogs />} />
          <Route path="/domain/:domainName/blog" element={<Blogs />} />
          <Route path="/domain/:domainName/messages" element={<Messages />} />
          <Route path="/domain/:domainName/meta" element={<DomainMETA />} />
          <Route path="/domain/:domainName/subscription" element={<DomainSubscription />} />
          <Route path="/domain/:domainName/archive" element={<DomainArchive />} />
          <Route path="/domain/:domainName/forms" element={<DomainArchive />} />
          <Route path="/domain/:domainName" element={<Domain />} />
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
