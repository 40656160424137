import AxiosInstance from "./AxiosInstance";

const auth = {
  getBlogsByDomain: async (domainName) => {
    const res = await AxiosInstance.get(`blog/domain/client?domain=${domainName}`);
    return res.data;
  },
  getBlogByDomainAndTitle: async (domainName, slug) => {
    const res = await AxiosInstance.get(`blog/domain-title?domain=${domainName}&slug=${slug}`);
    return res.data;
  },
  getBlogByID: async (blogId, domainName) => {
    const res = await AxiosInstance.get(`blog/${blogId}?domain=${domainName}`);
    return res.data;
  },
  getBlogAnalysisByID: async (blogId, domainName) => {
    const res = await AxiosInstance.get(`blog/analysis/${blogId}?domain=${domainName}`);
    return res.data;
  },
  createBlog: async (domainName, data) => {
    const res = await AxiosInstance.post(`blog/?domain=${domainName}`, data);
    return res.data;
  },
  updateBlog: async (blogId, data, domainName) => {
    const res = await AxiosInstance.put(`blog/${blogId}?domain=${domainName}`, data);
    return res.data;
  },
  updateBlogMETAById: async (blogId, domainName, body) => {
    const res = await AxiosInstance.put(`/blog/meta/${blogId}?domain=${domainName}`, body);
    return res.data;
  },
  copyDomainMETAToBlogByID: async (blogId) => {
    const res = await AxiosInstance.put(`/blog/meta/domain-copy/${blogId}`);
    return res.data;
  },
  deleteBlog: async (blogId, domainName) => {
    const res = await AxiosInstance.delete(`blog/${blogId}?domain=${domainName}`);
    return res.data;
  },
  addImage: async (blogId, domainName, data, option) => {
    const res = await AxiosInstance.post(`blog/media/${blogId}?domain=${domainName}`, data, option);
    // return image
    return res.data.data;
  },
};

export default auth;
