/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Box, Card, CircularProgress, Grid } from "@mui/material";
import pageApi from "api/page";
import { useNavigate, useParams } from "react-router";
import { useMaterialUIController } from "context";
import { REF, REF_ID, REF_PAGE_ROUTE, REF_MODAL, REF_SELECTED_ITEM, IMAGE } from "utils/constant";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { isArray } from "lodash";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Masonry } from "@mui/lab";

const SelectedPages = ({ path, data, updatePageInfo }) => {
  const { pageId, domainName } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pages, setPages] = useState(null);

  const getAllPages = async () => {
    try {
      const res = await pageApi.getPageByDomainAndRoute(domainName, data[REF][REF_PAGE_ROUTE]);
      setPages(res);
    } catch (error) {
      navigate(`/domain/${domainName}/page/${pageId}`);
    }
  };

  const checkIfIdExists = (refId) => {
    return Array.isArray(data[REF][REF_ID])
      ? data[REF][REF_ID].find((e) => e === refId)
      : data[REF][REF_ID] === refId;
  };

  const onAddRefId = (refId) => {
    updatePageInfo(
      `${path}.${REF_ID}`,
      Array.isArray(data[REF][REF_ID]) ? [...data[REF][REF_ID], refId] : refId
    );
  };
  const onRemoveRefId = (refId) => {
    updatePageInfo(
      `${path}.${REF_ID}`,
      Array.isArray(data[REF][REF_ID]) ? data[REF][REF_ID].filter((e) => e !== refId) : ""
    );
  };

  const onClickHandler = (refId) => {
    checkIfIdExists(refId) ? onRemoveRefId(refId) : onAddRefId(refId);
  };

  useEffect(() => {
    getAllPages();
  }, []);

  return pages ? (
    <MDBox sx={{ marginTop: 3, paddingY: 3 }}>
      <Masonry columns={3} spacing={1}>
        {pages.map(
          (page) =>
            page._id !== pageId && (
              <Grid item xs={4} height="auto" key={page._id}>
                <Card sx={{ paddingY: 2, paddingX: 4 }}>
                  <MDTypography>{page.page_route}</MDTypography>
                  <MDBox display="flex" justifyContent="space-around" marginTop={3}>
                    <Link to={`/domain/${domainName}/page/${page._id}`} target="_blank">
                      <MDButton width={100} size="small">
                        Open
                        <OpenInNewIcon />
                      </MDButton>
                    </Link>
                    {(Array.isArray(data[REF][REF_ID]) ||
                      typeof data[REF][REF_ID] === "string") && (
                      <MDButton
                        width={100}
                        size="small"
                        variant="gradient"
                        color={checkIfIdExists(page._id) ? "error" : "info"}
                        onClick={() => onClickHandler(page._id)}
                      >
                        {checkIfIdExists(page._id) ? "REMOVE" : "ADD"}
                      </MDButton>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            )
        )}
      </Masonry>
    </MDBox>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "40vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
};

export default SelectedPages;
