/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Card, CircularProgress } from "@mui/material";

import domainApi from "../../api/domain";
import pageApi from "../../api/page";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

const COLUMNS = [
  { Header: "Pages", accessor: "page_route", align: "left" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "Views", accessor: "view", align: "center" },
  { Header: "META", accessor: "meta", align: "center" },
  { Header: "Views Analytics", accessor: "viewAnalytics", align: "center" },
  { Header: "Edit", accessor: "action", align: "center" },
];

const COLUMNS_CUSTOM_PAGES = [
  { Header: "Pages", accessor: "page_route", align: "left" },
  { Header: "Number of pages", accessor: "numberOfPages", align: "center" },
  { Header: "Views", accessor: "view", align: "center" },
  { Header: "View", accessor: "actionShow", align: "center" },
  { Header: "Create", accessor: "action", align: "center" },
];

const COLUMNS_FORMS = [
  { Header: "Name", accessor: "key", align: "left" },
  { Header: "Messages", accessor: "messages", align: "center" },
  { Header: "View", accessor: "action", align: "center" },
];

const COLUMNS_BLOGS = [
  { Header: "Title", accessor: "title", align: "left" },
  { Header: "Slug", accessor: "slug", align: "left" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "Views", accessor: "view", align: "center" },
  { Header: "META", accessor: "meta", align: "center" },
  { Header: "Views Analytics", accessor: "viewAnalytics", align: "center" },
  { Header: "Edit", accessor: "action", align: "center" },
];

function Domain() {
  const { domainName } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainInfo, setDomainInfo] = useState(null);
  const [pagesInfo, setPagesInfo] = useState(null);
  const [customPagesInfo, setCustomPagesInfo] = useState(null);
  const [formsInfo, setFormsInfo] = useState(null);
  const [messagesAnalysis, setMessagesAnalysis] = useState(null);
  const [pagesAnalysis, setPagesAnalysis] = useState(null);
  const [blogsRow, setBlogsRow] = useState([]);
  const [metaRow, setMetaRow] = useState(null);

  const navigateToPage = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}`);
  };
  const navigateToPageAnalysis = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}/analysis`);
  };
  const navigateToBlog = (blogId) => {
    navigate(`/domain/${domainName}/blog/${blogId}`);
  };
  const navigateToCreateBlog = () => {
    navigate(`/domain/${domainName}/blog/create`);
  };
  const navigateToBlogAnalysis = (blogId) => {
    navigate(`/domain/${domainName}/blog/${blogId}/analysis`);
  };
  const navigateToUpdatingMETA = () => {
    navigate(`/domain/${domainName}/meta`);
  };
  const navigateToPageMETA = (pageId) => {
    navigate(`/domain/${domainName}/page/${pageId}/meta`);
  };
  const navigateToBlogMETA = (blogId) => {
    navigate(`/domain/${domainName}/blog/${blogId}/meta`);
  };
  const navigateToCreateCustomPage = (page_route) => {
    navigate({
      pathname: `/domain/${domainName}/page/create`,
      search: `?route=${page_route}`,
    });
  };
  const navigateToCustomPage = (page_route) => {
    navigate(`/domain/${domainName}/page/custom?route=${page_route}`);
  };
  const navigateToCustomForms = (formsKey) => {
    navigate(`/domain/${domainName}/messages?form=${formsKey}`);
  };

  const getDomainInfo = async () => {
    try {
      const res = await domainApi.getDomainInfo(domainName);

      const isMETAActivate = res.domain.isMETAActivate;

      const newMessageAnalysis = {
        datasets: {
          data: [],
          label: "messages",
        },
        labels: [],
      };
      const newPagesAnalysis = {
        datasets: {
          data: [],
          label: "pages",
        },
        labels: [],
      };

      res.messages.analysis.forEach((msg) => {
        newMessageAnalysis.datasets.data.push(msg.count.toString());
        newMessageAnalysis.labels.push(msg._id.toString());
      });
      res.viewsPages.analysis.forEach((day) => {
        newPagesAnalysis.datasets.data.push(day.count);
        newPagesAnalysis.labels.push(day._id);
      });
      setPagesAnalysis(newPagesAnalysis);
      setMessagesAnalysis(newMessageAnalysis);

      const newPagesInfo = [];
      res.pagesInfo.pages.map((page) => {
        newPagesInfo.push({
          page_route: domainName + page.page_route,
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.views}
            </MDTypography>
          ),
          meta: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton
                disabled={!isMETAActivate}
                color="primary"
                size="small"
                onClick={() => navigateToPageMETA(page._id)}
              >
                META
              </MDButton>
            </MDBox>
          ),
          viewAnalytics: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="info" size="small" onClick={() => navigateToPageAnalysis(page._id)}>
                Analysis
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToPage(page._id)}>
              <MDButton color="success" size="small">
                Edit
              </MDButton>
            </MDBox>
          ),
        });
      });
      setPagesInfo(newPagesInfo);

      const newCustomPagesInfo = [];
      res.customPagesInfo.customPages.map((page) => {
        newCustomPagesInfo.push({
          page_route: domainName + page.route,
          view: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.views}
            </MDTypography>
          ),
          numberOfPages: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.numberOfPages}
            </MDTypography>
          ),
          actionShow: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToCustomPage(page.route)}>
              <MDButton color="info" size="small">
                View
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToCreateCustomPage(page.route)}>
              <MDButton color="success" size="small">
                Create
              </MDButton>
            </MDBox>
          ),
        });
      });
      setCustomPagesInfo(newCustomPagesInfo);

      const newFormsInfo = [];
      res.formsInfo.forms.map((form) => {
        newFormsInfo.push({
          key: form.key,
          messages: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {form.messages}
            </MDTypography>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer" onClick={() => navigateToCustomForms(form.key)}>
              <MDButton color="secondary" size="small">
                View
              </MDButton>
            </MDBox>
          ),
        });
      });
      setFormsInfo(newFormsInfo);

      const blogRows = [];
      res.blogsInfo.blogs.forEach((blog) => {
        blogRows.push({
          title: blog.title,
          slug: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {blog.slug.substring(0, 10)}
              {blog.slug.length > 10 && "..."}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(blog.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(blog.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {blog.views}
            </MDTypography>
          ),
          meta: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton
                disabled={!isMETAActivate}
                color="primary"
                size="small"
                onClick={() => navigateToBlogMETA(blog._id)}
              >
                META
              </MDButton>
            </MDBox>
          ),
          viewAnalytics: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="info" size="small" onClick={() => navigateToBlogAnalysis(blog._id)}>
                Analysis
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="success" size="small" onClick={() => navigateToBlog(blog._id)}>
                Edit
              </MDButton>
            </MDBox>
          ),
        });
      });
      setBlogsRow(blogRows);

      if (isMETAActivate) {
        let newMetaRows = [{ ...res.domain.meta, icon: undefined, image: undefined }];
        let newMetaColumns = [];
        Object.keys(res.domain.meta).forEach((key) => {
          if (key !== "image" && key !== "icon")
            newMetaColumns.push({ Header: key.toUpperCase(), accessor: key, align: "center" });
        });
        setMetaRow({ rows: newMetaRows, columns: newMetaColumns });
      }

      setDomainInfo(res);
    } catch (error) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    getDomainInfo();
  }, [domainName]);

  return domainInfo ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total Visit"
                count={domainInfo.viewsPages.count}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total Pages"
                count={domainInfo.customPagesInfo.count + domainInfo.pagesInfo.count || 0}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="storage_icon"
                title="Total Storage"
                count={`${domainInfo.domain.usedStorage} / ${domainInfo.domain.limitStorage} MB`}
                percentage={{
                  color: "success",
                  amount: `${(
                    (domainInfo.domain.usedStorage / domainInfo.domain.limitStorage) *
                    100
                  ).toFixed(2)}%`,
                  label: (
                    <>
                      Used storage
                      <Link to="archive">
                        <MDTypography
                          fontSize={13}
                          sx={{ marginLeft: 3 }}
                          color="success"
                          fontWeight="bold"
                        >
                          MORE
                        </MDTypography>
                      </Link>
                    </>
                  ),
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="person_add"
                title="Total Messages"
                count={domainInfo.messages.count || 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="attach_money"
                title="End Subscription"
                count={domainInfo.subscription}
                percentage={{
                  amount: domainInfo.subscription,
                  label: (
                    <>
                      Days Left till the end of your subscription
                      <Link to="subscription">
                        <MDTypography
                          fontSize={13}
                          sx={{ marginLeft: 3 }}
                          color="success"
                          fontWeight="bold"
                        >
                          MORE
                        </MDTypography>
                      </Link>
                    </>
                  ),
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox mt={4.5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Daily Visits"
                  date="updated just now"
                  chart={pagesAnalysis}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="dark"
                  title="Messages Sends"
                  date={
                    domainInfo.isActiveDefaultContactUs && (
                      <MDButton
                        color="dark"
                        size="small"
                        onClick={() => navigate(`/domain/${domainName}/messages`)}
                      >
                        Show All Messages
                      </MDButton>
                    )
                  }
                  chart={messagesAnalysis}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {domainInfo.domain.isMETAActivate && metaRow && (
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="primary"
              borderRadius="lg"
              coloredShadow="success"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDTypography variant="h6" color="white">
                Domain META
              </MDTypography>
              <MDButton color="success" size="small" onClick={() => navigateToUpdatingMETA()}>
                Update
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns: metaRow.columns, rows: metaRow.rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        )}

        {formsInfo?.length > 0 && (
          <Card sx={{ marginTop: 7 }}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              coloredShadow="success"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDTypography variant="h6" color="white">
                Forms
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns: COLUMNS_FORMS, rows: formsInfo }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        )}

        <Card sx={{ marginTop: 7 }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Pages
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: pagesInfo }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>

        {customPagesInfo?.length > 0 && (
          <Card sx={{ marginTop: 7 }}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="success"
              borderRadius="lg"
              coloredShadow="success"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDTypography variant="h6" color="white">
                Custom Pages
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns: COLUMNS_CUSTOM_PAGES, rows: customPagesInfo }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        )}

        {blogsRow && domainInfo.domain.isBlogActivate && (
          <Card sx={{ marginTop: 7 }}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              coloredShadow="secondary"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDTypography variant="h6" color="white">
                Blogs
              </MDTypography>
              <MDButton color="success" size="small" onClick={() => navigateToCreateBlog()}>
                Create
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns: COLUMNS_BLOGS, rows: blogsRow }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
                canSearch
              />
            </MDBox>
          </Card>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Domain;
