/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Modal, Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Masonry } from "@mui/lab";

import MDTypography from "components/MDTypography";
import MyPdfViewer from "components/MyPdfViewer";
import MDBox from "components/MDBox";

import archiveApi from "api/archive";

import { useMaterialUIController } from "context";

import { PDF_FILE, IMAGE_FILE } from "utils/constant";
import ImageWithLoadingSpinner from "components/ImageWithLoadingSpinner";
import MDButton from "components/MDButton";

function ModalFiles({
  openModalFile,
  onCloseHandler,
  getRootProps,
  getInputProps,
  isDragActive,
  fileName,
  selectFileFromArchive,
}) {
  const { domainName } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainArchive, setDomainArchive] = useState([]);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDomainArchive = async () => {
    setLoading(true);
    try {
      const res = await archiveApi.get(domainName, page, fileName);
      if (res.pagination.current_page === res.pagination.total_pages) setIsLastPage(true);
      setDomainArchive((prevData) => [...prevData, ...res.data]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!openModalFile) return;
    getDomainArchive();
  }, [openModalFile, page]);

  useEffect(() => {
    if (!openModalFile) {
      setDomainArchive([]);
      setPage(1);
      setIsLastPage(false);
    }
  }, [openModalFile]);

  const loadMoreHandler = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Modal
      open={openModalFile}
      onClose={onCloseHandler}
      disableEnforceFocus
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MDBox
        sx={{
          width: "50%",
          margin: "auto",
        }}
      >
        <Card sx={{ paddingTop: 5, paddingX: 5 }}>
          <MDTypography fontWeight="medium" marginBottom={2}>
            Upload new File from you device
          </MDTypography>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: 100,
              border: 2,
              borderRadius: 2,
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <MDTypography fontWeight="medium">Drop the files here ...</MDTypography>
              </>
            ) : (
              <Stack direction="column" justifyContent="center" alignItems="center">
                <MDTypography fontWeight="medium">
                  Drag drop some file here, or click to select files
                </MDTypography>
                <CloudUploadIcon fontSize="large" />
              </Stack>
            )}
          </MDBox>
          {domainArchive ? (
            <MDBox sx={{ paddingY: 5, overflowX: "auto", height: domainArchive ? "50vh" : 0 }}>
              <MDTypography fontWeight="medium">Or Click to chose from archive</MDTypography>
              <Masonry columns={3} spacing={1}>
                {domainArchive.map((file) => (
                  <div key={file._id}>
                    {fileName === IMAGE_FILE && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => selectFileFromArchive(file.name)}
                      >
                        <ImageWithLoadingSpinner
                          src={`${file.name}?w=162&auto=format`}
                          srcSet={`${file.name}?w=162&auto=format&dpr=2 2x`}
                          // alt={item.title}
                          loading="lazy"
                          style={{
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            display: "block",
                            width: "100%",
                          }}
                        />
                      </div>
                    )}
                    {fileName === PDF_FILE && (
                      <MyPdfViewer
                        file={file.name}
                        onClickDoc={() => selectFileFromArchive(file.name)}
                      />
                    )}
                  </div>
                ))}
              </Masonry>
              {domainArchive?.length > 0 && !isLastPage && (
                <MDButton
                  sx={{ alignSelf: "center" }}
                  size="small"
                  color="success"
                  onClick={loadMoreHandler}
                >
                  Load More
                  {loading && (
                    <CircularProgress color={"white"} size="0.7rem" sx={{ marginLeft: 1 }} />
                  )}
                </MDButton>
              )}
            </MDBox>
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "100vh",
                width: "100%",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color={!darkMode ? "inherit" : "white"} />
            </Box>
          )}
        </Card>
      </MDBox>
    </Modal>
  );
}

export default ModalFiles;
