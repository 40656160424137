/* eslint-disable */

import React from "react";
import { CircularProgress, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function CircularProgressWithLabel(props) {
  return (
    <Modal open={props.loading || props.value || false}>
      <MDBox
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        {props.value ? (
          <MDBox sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" color="success" {...props} />
            <MDBox
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDTypography variant="caption" component="div">
                {`${Math.round(props.value)}%`}
              </MDTypography>
            </MDBox>
          </MDBox>
        ) : (
          <CircularProgress color="success" />
        )}
      </MDBox>
    </Modal>
  );
}

export default CircularProgressWithLabel;
