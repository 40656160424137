// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Box, CircularProgress, Modal } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";

import { useOwnerStore } from "store";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

function Overview() {
  const ownerData = useOwnerStore((state) => state.ownerData);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [isEditMode, setIsEditMode] = useState(false);

  const onCloseEditMode = () => setIsEditMode(false);
  const onOpenEditMode = () => setIsEditMode((old) => !old);

  return ownerData ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                info={{
                  name: ownerData.name,
                  email: ownerData.email,
                  phone: ownerData.phone,
                }}
                action={{ tooltip: "Profile", onclick: onOpenEditMode }}
                shadow={false}
                isEditMode={isEditMode}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Overview;
