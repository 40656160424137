import AxiosInstance from "./AxiosInstance";

const fileExtension = {
  getAllExt: async () => {
    const res = await AxiosInstance.get("file-extension");
    return res.data;
  },
};

export default fileExtension;
