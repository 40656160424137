import AxiosInstance from "./AxiosInstance";

const page = {
  getMyPagesByDomain: async (domainName) => {
    const res = await AxiosInstance.get(`/page/domain-by-name?domainName=${domainName}`);
    return res.data;
  },

  getPageById: async (pageId) => {
    const res = await AxiosInstance.get(`/page/${pageId}`);
    return res.data;
  },

  getPageByDomainAndRoute: async (domainName, pageRoute) => {
    const res = await AxiosInstance.get(
      `page/domain-and-route?domainName=${domainName}&page_route=${pageRoute}`
    );
    return res.data;
  },

  getPageByDomainAndRouteWithAnalysis: async (domainName, pageRoute) => {
    const res = await AxiosInstance.get(
      `page/domain-and-route/analysis?domainName=${domainName}&page_route=${pageRoute}`
    );
    return res.data;
  },

  updatePageById: async (pageId, body) => {
    const res = await AxiosInstance.put(`/page/${pageId}`, {
      page_data: { ...body },
    });
    return res.data;
  },
  updatePageMETAById: async (pageId, body) => {
    const res = await AxiosInstance.put(`/page/meta/${pageId}`, body);
    return res.data;
  },
  copyDomainMETAToPageByID: async (pageId) => {
    const res = await AxiosInstance.put(`/page/meta/domain-copy/${pageId}`);
    return res.data;
  },
  createCustomPage: async (domainId, body) => {
    const res = await AxiosInstance.post(`page/custom/${domainId}`, body);
    return res.data;
  },
  deleteCustomPage: async (pageId) => {
    const res = await AxiosInstance.delete(`page/custom/${pageId}`);
    return res.data;
  },

  addImage: async (pageId, body, option) => {
    const res = await AxiosInstance.post(`/page/media/${pageId}`, body, option);
    return res.data;
  },
  updateImage: async (pageId, body, option) => {
    const res = await AxiosInstance.put(`/page/media/${pageId}`, body, option);
    return res.data;
  },
  deleteImage: async (pageId) => {
    const res = await AxiosInstance.delete(`/page/media/${pageId}`);
    return res.data;
  },

  addElementToArray: async (pageId, path) => {
    const res = await AxiosInstance.put(`/page/array/${pageId}`, {
      path,
    });
    return res.data;
  },

  removeElementFromArray: async (pageId, path) => {
    const res = await AxiosInstance.delete(`/page/array/${pageId}?path=${path}`);
    return res.data;
  },
};

export default page;
