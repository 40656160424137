import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useOwnerStore, useDomainStore } from "store";
import authApi from "api/auth";
import ownerApi from "api/owner";
import { CircularProgress } from "@mui/material";

function Basic() {
  const nav = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    getValues,
  } = useForm();

  const resetData = useDomainStore((state) => state.resetData);
  const setOwnerStore = useOwnerStore((state) => state.setOwnerStore);
  const setIsLogin = useOwnerStore((state) => state.setIsLogin);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onSubmit = async () => {
    const value = getValues();
    setLoading(true);
    try {
      const res = await authApi.loginOwner(value);
      setOwnerStore(res.token);
      const owner = await ownerApi.getOwnerByToken();
      setIsLogin(owner.passwordType !== "owner_pass");
      resetData();
      nav("/");
    } catch (error) {
      setError("login", { type: "login", message: error.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                register={register("email", { required: true })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                register={register("password", { minLength: 8, required: true })}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1} onClick={onSubmit}>
              <MDButton variant="gradient" color="info" fullWidth>
                sign in
                {loading && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Do you forgot your password?{" "}
                <MDTypography
                  component={Link}
                  to="/forgot-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forget Password
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
