/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useLocation, useParams } from "react-router-dom";
import { Box, Card, CircularProgress } from "@mui/material";

import contactUsApi from "../../api/contactus";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import { toast } from "react-toastify";
import { useMaterialUIController } from "context";
import moment from "moment";
import splitSpace from "utils/splitSpace";
import MDButton from "components/MDButton";
import ConfirmDialog from "components/ConfirmDialog";

function Messages() {
  const { domainName } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const formsKey = query.get("form");

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainContact, setDomainContact] = useState(null);
  const [messageIdNeedToDelete, setMessageIdNeedToDelete] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const openDialogHandler = (messageId) => {
    setMessageIdNeedToDelete(messageId);
    setOpenDialog(true);
  };
  const closeDialogHandler = () => {
    setMessageIdNeedToDelete(null);
    setOpenDialog(false);
  };

  const onDeleteMessageHandler = async () => {
    if (!messageIdNeedToDelete) return;
    const id = toast.loading("Deleting Message Please Wait...");
    try {
      const res = await contactUsApi.deleteMessage(messageIdNeedToDelete);
      await getDomainContact();
      toast.update(id, {
        render: "Message Delete",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
    } catch (error) {
      toast.update(id, {
        render: "Error: in Deleting",
        type: "error",
        isLoading: false,
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
      });
    }
  };

  const getDomainContact = async () => {
    try {
      const res = await contactUsApi.getAllMessages(domainName, formsKey);

      const table = {
        column: [],
        row: [],
      };

      if (res.length > 0) {
        Object.keys(res[0].contactMessage).forEach((key, index) => {
          table.column.push({
            Header: key,
            accessor: key,
            align: index === 0 ? "left" : "center",
          });
        });
        table.column.push({ Header: "Date", accessor: "createdAt", align: "center" });
        table.column.push({ Header: "Delete", accessor: "delete", align: "center" });
        res.forEach((e) => {
          table.row.push({
            ...e.contactMessage,
            createdAt: moment(e.createdAt).format("DD-MM-YYYY hh:mm A"),
            delete: (
              <MDButton color="error" onClick={() => openDialogHandler(e._id)} size="small">
                Delete
              </MDButton>
            ),
          });
        });
      }
      setDomainContact(table);
    } catch (error) {}
  };

  useEffect(() => {
    getDomainContact();
  }, [domainName]);

  return domainContact ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
          >
            <MDTypography variant="h6" color="white">
              {formsKey ? splitSpace(formsKey) : "Contact Us"} Messages
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: domainContact.column, rows: domainContact.row }}
              isSorted={true}
              entriesPerPage={true}
              showTotalEntries={true}
              noEndBorder
              canSearch
            />
          </MDBox>
        </Card>
      </MDBox>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDialogHandler}
        onConfirm={onDeleteMessageHandler}
        title={"Delete Message"}
        content={"Are you sure you want to delete message"}
        confirmTitle="Delete"
      />
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Messages;
