import AxiosInstance from "./AxiosInstance";

const owner = {
  getOwnerByToken: async () => {
    const res = await AxiosInstance.get("/owner/me");
    return res.data;
  },

  updateOwnerInfo: async (body) => {
    const res = await AxiosInstance.put("/owner/update", body);
    return res.data;
  },

  updateOwnerNotification: async (token) => {
    const res = await AxiosInstance.put("/owner/update/notification", { token });
    return res.data;
  },

  updateOwnerPassword: async (body) => {
    const res = await AxiosInstance.put("/owner/update-password", body);
    return res.data;
  },
};

export default owner;
