/* eslint-disable */

const mongoObjectId = function () {
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

const base64ToFile = (base64Data) => {
  const arr = base64Data.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const ext = mime.split("/")[1];
  const filename = mongoObjectId() + "." + ext;
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export default base64ToFile;
