/* eslint-disable */
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Collapse, Stack } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import MDTypography from "components/MDTypography";
import { countries } from "country-data";

function ExpandCountries({ viewDetails }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => setExpanded((old) => !old);

  return (
    <div onClick={handleExpandClick} style={{ cursor: "pointer" }}>
      <Stack direction="row" spacing={10} width="100%" height="100%">
        <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
          Show
        </MDTypography>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {Object.keys(viewDetails).map((e) => (
          <div key={e}>
            <MDTypography
              component="a"
              variant="caption"
              color="text"
              fontWeight="medium"
              textAlign="right"
            >
              {e !== "Other" && <ReactCountryFlag countryCode={e} />}{" "}
              {e !== "Other" ? countries[e].name : "Other"} : {viewDetails[e]}
            </MDTypography>
            <br />
          </div>
        ))}
      </Collapse>
    </div>
  );
}

export default ExpandCountries;
