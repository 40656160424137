/* eslint-disable */

import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

function MDDialog({ open, onClose, onConfirm, title, confirmTitle, loading, autoClose, children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const onConfirmHandler = async () => {
    await onConfirm();
    if (autoClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { backgroundColor: darkMode && "#202940" } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} color="error" size="small">
          Cancel
        </MDButton>
        <MDButton onClick={onConfirmHandler} color="success" size="small" disabled={loading}>
          {confirmTitle}
          {loading && <CircularProgress color={"white"} size="0.7rem" sx={{ marginLeft: 1 }} />}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

MDDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  autoClose: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

MDDialog.defaultProps = {
  loading: false,
  autoClose: true,
};

export default MDDialog;
