/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CircularProgress, Stack, Tab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { TabPanel, TabContext, TabList } from "@mui/lab";

import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useMaterialUIController } from "context";
import blogApi from "api/blog";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";

const COLUMNS_BLOGS = [
  { Header: "Title", accessor: "title", align: "left" },
  { Header: "Slug", accessor: "slug", align: "left" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "Views", accessor: "view", align: "center" },
  { Header: "META", accessor: "meta", align: "center" },
  { Header: "Views Analytics", accessor: "viewAnalytics", align: "center" },
  { Header: "Edit", accessor: "action", align: "center" },
];

export default function Blogs() {
  const { domainName } = useParams();

  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [blogs, setBlogs] = useState(null);

  const navigateToBlog = (blogId) => {
    navigate(`/domain/${domainName}/blog/${blogId}`);
  };
  const navigateToCreateBlog = () => {
    navigate(`/domain/${domainName}/blog/create`);
  };
  const navigateToBlogAnalysis = (blogId) => {
    navigate(`/domain/${domainName}/blog/${blogId}/analysis`);
  };
  const navigateToBlogMETA = (blogId) => {
    navigate(`/domain/${domainName}/blog/${blogId}/meta`);
  };

  const getAllBlogs = async () => {
    try {
      const res = await blogApi.getBlogsByDomain(domainName);

      const blogRows = [];
      res.blogs.forEach((blog) => {
        blogRows.push({
          title: blog.title,
          slug: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {blog.slug.substring(0, 10)}
              {blog.slug.length > 10 && "..."}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(blog.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(blog.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {blog.views}
            </MDTypography>
          ),
          meta: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton
                disabled={!res.isMETAActivate}
                color="primary"
                size="small"
                onClick={() => navigateToBlogMETA(blog._id)}
              >
                META
              </MDButton>
            </MDBox>
          ),
          viewAnalytics: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="info" size="small" onClick={() => navigateToBlogAnalysis(blog._id)}>
                Analysis
              </MDButton>
            </MDBox>
          ),
          action: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="success" size="small" onClick={() => navigateToBlog(blog._id)}>
                Edit
              </MDButton>
            </MDBox>
          ),
        });
      });
      setBlogs(blogRows);
    } catch (error) {
      navigate(`/domain/${domainName}`);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return blogs ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox minHeight="80vh">
        <Card sx={{ marginTop: 7 }}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="secondary"
            borderRadius="lg"
            coloredShadow="secondary"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Blogs
            </MDTypography>
            <MDButton color="success" size="small" onClick={() => navigateToCreateBlog()}>
              Create
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS_BLOGS, rows: blogs }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              canSearch
            />
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}
