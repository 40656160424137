/* eslint-disable */

import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";

const ImageWithLoadingSpinner = ({ ...props }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [loading, setLoading] = useState(true);

  return (
    <div>
      {loading && <CircularProgress color={!darkMode ? "inherit" : "white"} size="1rem" />}
      <img {...props} onLoad={() => setLoading(false)} onError={() => setLoading(false)} />
    </div>
  );
};

export default ImageWithLoadingSpinner;
