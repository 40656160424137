import AxiosInstance from "./AxiosInstance";

const auth = {
  updateDomainMeta: async (domainId, path, data, option) => {
    const res = await AxiosInstance.post(
      `media/meta/domain/${domainId}?path=${path}`,
      data,
      option
    );
    return res.data;
  },
  updatePageMeta: async (pageId, path, data, option) => {
    const res = await AxiosInstance.post(`media/meta/page/${pageId}?path=${path}`, data, option);
    return res.data;
  },
  updateBlogMeta: async (blogId, path, data, option) => {
    const res = await AxiosInstance.post(`media/meta/blog/${blogId}?path=${path}`, data, option);
    return res.data;
  },
};

export default auth;
