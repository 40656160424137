/* eslint-disable */
import create from "zustand";
import { persist } from "zustand/middleware";

const notificationStore = (set) => ({
  number: null,
  data: null,
  isOpen: false,
  setNotificationStore: ({ number, data }) => set(() => ({ number, data })),
  openNotification: () => set((state) => ({ isOpen: !state.isOpen })),
});

export const useNotificationStore = create(notificationStore);
