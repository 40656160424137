import AxiosInstance from "./AxiosInstance";

const notification = {
  getMyNotification: async (page = 1, perPage = 25) => {
    const res = await AxiosInstance.get(`/notification/owner/me?page=${page}&perPage=${perPage}`);
    return res.data;
  },
};

export default notification;
