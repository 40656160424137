/* eslint-disable */

const getObjectPaths = (obj, path = "") => {
  const paths = [];

  for (const key in obj) {
    const newPath = path ? `${path}.${key}` : key;
    if (typeof obj[key] === "object") {
      paths.push(...getObjectPaths(obj[key], newPath));
    } else {
      paths.push(newPath);
    }
  }

  return paths;
};

export default getObjectPaths;
