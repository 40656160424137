/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Card, CircularProgress } from "@mui/material";

import subscriptionApi from "api/subscription";

import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import moment from "moment";

function DomainSubscription() {
  const { domainName } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainSubscription, setDomainSubscription] = useState(null);

  const getDomainSubscription = async () => {
    try {
      const res = await subscriptionApi.getByDomainName(domainName);
      setDomainSubscription(res);
    } catch (error) {
      navigate(`/domain/${domainName}`);
    }
  };

  useEffect(() => {
    getDomainSubscription();
  }, [domainName]);

  return domainSubscription ? (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ padding: 5, margin: 3 }}>
        <Grid container spacing={3}>
          {domainSubscription.map((subscription) => (
            <Grid item xs={12} md={6} lg={6} key={subscription._id}>
              <Card sx={{ padding: 5 }}>
                <MDTypography>
                  Amount: {subscription.amount} {subscription.currency}
                </MDTypography>
                <MDTypography>Payment Type: {subscription.paymentType}</MDTypography>
                <MDTypography>
                  End Subscription: {moment(subscription.endSubscription).format("DD-MM-YYYY")}
                </MDTypography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default DomainSubscription;
