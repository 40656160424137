/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import analysisApi from "api/analysis";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import _ from "lodash";
import DataTable from "examples/Tables/DataTable";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";

const COLUMNS = [
  { Header: "Year", accessor: "year", align: "left" },
  { Header: "Views", accessor: "view", align: "left" },
  { Header: "Month Analysis", accessor: "month_analysis", align: "left" },
];

export default function AnalysisYearBlog() {
  const { blogId } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pageAnalysis, setPageAnalysis] = useState(null);
  const [pageViewsRow, setPageViewsRow] = useState(null);

  const getPageInfo = async () => {
    try {
      const res = await analysisApi.getAnalysisBlogByYears(blogId);

      const newPagesAnalysis = {
        datasets: {
          data: [],
          label: "pages",
        },
        labels: [],
      };

      res.forEach((year) => {
        newPagesAnalysis.labels.push(year._id);
        newPagesAnalysis.datasets.data.push(year.views);
      });

      const pageViewsRow = [];
      res.forEach((year) => {
        pageViewsRow.push({
          year: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {year._id}
            </MDTypography>
          ),
          view: (
            <>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {year.views}
              </MDTypography>
            </>
          ),
          month_analysis: (
            <MDBox ml={-1} cursor="pointer">
              <MDButton color="info" size="small" onClick={() => navigate(`${year._id}`)}>
                Months
              </MDButton>
            </MDBox>
          ),
        });
      });
      setPageViewsRow(pageViewsRow);
      setPageAnalysis(newPagesAnalysis);
    } catch (error) {
      toast.error(error.response);
    }
  };

  useEffect(() => {
    getPageInfo();
  }, []);

  return pageAnalysis ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <ReportsLineChart
          color="info"
          title="Yearly Visits"
          date="updated just now"
          chart={pageAnalysis}
        />
      </MDBox>

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Years Analysis
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: pageViewsRow }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}
