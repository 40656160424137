// Skip client from passing this fields
exports.BLOG = "blog";
exports.PAGE = "page";
exports.IMAGE = "image";

exports.REF = "_ref";
exports.REF_ID = "_id";
exports.REF_PAGE_ROUTE = "_pageRoute";
exports.REF_MODAL = "_modal";
exports.REF_SELECTED_ITEM = "_selectedItem";

exports.IMAGE_FILE = "_image";
exports.PDF_FILE = "_pdf";

exports.IMAGE_WIDTH = "_width";
exports.IMAGE_HEIGHT = "_height";

exports.IGNORE_INPUT_NAME = ["_WIDTH", "_HEIGHT"];

exports.SKIP_CLIENT_REF = [this.REF_MODAL, this.REF_SELECTED_ITEM];
