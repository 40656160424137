import AxiosInstance from "./AxiosInstance";

const setting = {
  getPageSetting: async (domainName, pageRoute) => {
    const res = await AxiosInstance.get(
      `setting/owner/domain?domainName=${domainName}&page_route=${pageRoute}`
    );
    return res.data;
  },
};

export default setting;
