/* eslint-disable */
import { memo } from "react";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./index.css";

const MyPdfViewer = ({ file, onClickDoc }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div className="Example__container">
      <div className="Example__container__document">
        <div onClick={() => onClickDoc()} style={{ cursor: onClickDoc && "pointer" }}>
          <Document
            file={`${file}${file && file.includes("blob") ? "" : "?cors"}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page key={`page_${pageNumber}`} pageNumber={pageNumber} height={300} />
          </Document>
        </div>
        {numPages && (
          <MDBox sx={{ display: "flex", justifyContent: "space-around" }}>
            <MDButton onClick={previousPage} disabled={pageNumber <= 1}>
              <NavigateBeforeIcon />
            </MDButton>
            <MDTypography>
              {pageNumber}/{numPages}
            </MDTypography>
            <MDButton onClick={nextPage} disabled={pageNumber >= numPages}>
              <NavigateNextIcon />
            </MDButton>
          </MDBox>
        )}
      </div>
    </div>
  );
};

export default memo(MyPdfViewer);
