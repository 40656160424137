/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";

import PageFelidsObject from "layouts/page/components/PageFelidsObject";

import fileExtensionApi from "api/file-extension";
import settingApi from "api/setting";
import pageApi from "api/page";
import getObjectPaths from "utils/getObjectPaths";
import deepMap from "utils/deepMap";
import mongoObjectId from "utils/mongoObjectId";
import asyncDeepMap from "utils/asyncDeepMap";
import getAllThePathForFile from "utils/getAllThePathForFile";
import asyncMap from "utils/asyncMap";
import _ from "lodash";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";
import replaceInstanceFileWithEmptyArray from "utils/replaceInstanceFileWithEmptyArray";
import { REF_ID } from "utils/constant";

const NO_ACCEPT_SPECIAL_CHAR_WITH_DASH = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;
const NO_WHITE_SPACE = /^\S*$/;

const CreateCustomPage = () => {
  const { domainName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const methods = useForm();
  const pageInfo = methods.watch();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const query = new URLSearchParams(location.search);

  const [settingPage, setSettingPage] = useState(null);
  const [routeName, setRouteName] = useState("");
  const [fileExtension, setFileExtension] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressFile, setProgressFile] = useState(null);

  const getFileAnalysis = async () => {
    try {
      const res = await fileExtensionApi.getAllExt();
      setFileExtension(res);
    } catch (error) {
      toast.error(error.response);
    }
  };

  const getPageSetting = async () => {
    try {
      const res = await settingApi.getPageSetting(domainName, query.get("route"));
      Object.keys(res.data).forEach((key) => {
        methods.setValue(key, res.data[key]);
      });
      setSettingPage(res);
    } catch (error) {
      navigate(`domain/${domainName}`);
    }
  };

  const removeIndexFromArray = async (registerParams) => {
    const values = methods.getValues();
    const item = _.get(values, registerParams);
    if (item !== undefined) {
      const pathArr = registerParams.split(".");
      const lastItem = parseInt(pathArr.pop());
      const newPath = pathArr.join(".");

      _.update(values, newPath, (data) => {
        if (Array.isArray(data)) {
          data.splice(lastItem, 1);
        }
        return data;
      });

      const newPathValue = _.get(values, newPath);
      updatePageInfo(newPath, newPathValue);
    }
  };

  const checkIfAllowedExtension = (key) => {
    return fileExtension.find((e) => key.includes(e.name));
  };

  const isThereEmptyFields = (body) => {
    let isEmpty = false;
    const fileShouldBeTrueInArray = deepMap(pageInfo, (value) =>
      value instanceof File ? "FILE" : value
    );
    getObjectPaths(fileShouldBeTrueInArray).map((path) => {
      const value = _.get(fileShouldBeTrueInArray, path);
      if (typeof value === "boolean" || typeof value === "number") return;
      if (path.split(".")[path.split(".").length - 1] === REF_ID) return;
      if (_.isEmpty(value))
        throw {
          response: {
            data: {
              error: [
                {
                  context: {
                    label: path,
                  },
                  message: "Not Allowed to be empty",
                },
              ],
            },
          },
        };
    });
    return isEmpty;
  };

  const handleFileSubmission = async (pageId, File) => {
    const formData = new FormData();
    formData.append("image", File);

    try {
      const option = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressFile(percentage);
        },
      };
      const result = await pageApi.addImage(pageId, formData, option);
      return result;
    } catch (error) {
      toast.error(error?.response?.data?.error || JSON.stringify(error));
    } finally {
      setProgressFile(null);
    }
  };

  const uploadAllFiles = async (pageId, values) => {
    try {
      let newValues = { ...values };
      const allFilesPaths = getAllThePathForFile(newValues);
      await asyncMap(allFilesPaths, async (path, index) => {
        const res = await handleFileSubmission(pageId, _.get(newValues, path));
        _.set(newValues, path, res);
      });
      await pageApi.updatePageById(pageId, newValues);
    } catch (error) {
      let errorMessage = error.response.data.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.map((e) => {
          methods.setError(e.context.label, { message: e.message });
        });
        errorMessage = errorMessage.map((e) => `${e.context.label} ${e.message}`);
        toast.error(`Error: ${errorMessage}`);
      } else toast.error(`Error: ${errorMessage}`);
    }
  };

  const onSubmit = async () => {
    if (!routeName) return toast.error(`Page route should not ne empty`);
    methods.clearErrors();
    const values = methods.getValues();

    const newDataWithoutImage = await deepMap(values, (value) =>
      value instanceof File ? "" : value
    );

    const body = {
      page_route: settingPage.route,
      page_name: routeName,
      // page_data: newDataWithoutImage,
      page_data: replaceInstanceFileWithEmptyArray(values),
    };

    setLoading(true);
    try {
      isThereEmptyFields(values);
      const res = await pageApi.createCustomPage(settingPage.domainId, body);
      await uploadAllFiles(res._id, values);
      toast.success(`Page create it successfully`);
      navigate(`/domain/${domainName}/page/${res._id}`);
    } catch (error) {
      let errorMessage = error.response.data.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.map((e) => {
          methods.setError(e.context.label, { message: e.message });
        });
        errorMessage = errorMessage.map((e) => `${e.context.label} ${e.message}`);
        toast.error(`Error: ${errorMessage}`);
      } else toast.error(`Error: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  const updatePageInfo = (path, value) => {
    methods.setValue(path, value);
  };

  const onChangeName = (e) => {
    const value = e.target.value;
    if (NO_ACCEPT_SPECIAL_CHAR_WITH_DASH.test(value) || !NO_WHITE_SPACE.test(value)) return;
    setRouteName(value);
  };

  const getPageInfoByPath = (path) => {
    return _.get(pageInfo, path);
  };

  useEffect(() => {
    getFileAnalysis();
    getPageSetting();
  }, []);

  return settingPage && fileExtension ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} component="form" role="form">
        <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography variant="h6" color="text" fontWeight="bold" width={120}>
            {settingPage.route}
          </MDTypography>
          <MDInput label="Page Route" value={routeName} onChange={onChangeName} fullWidth />
        </MDBox>
        <FormProvider {...methods}>
          <PageFelidsObject
            data={pageInfo}
            register={methods.register}
            errors={methods.formState.errors}
            removeIndexFromArray={removeIndexFromArray}
            updatePageInfo={updatePageInfo}
            getPageInfoByPath={getPageInfoByPath}
            checkIfAllowedExtension={checkIfAllowedExtension}
            isAllowedToUploadFile={true}
          />
        </FormProvider>

        <MDBox mt={4} mb={1} onClick={onSubmit}>
          <MDButton variant="gradient" color="info" fullWidth>
            Submit
            {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
          </MDButton>
        </MDBox>
      </MDBox>
      <CircularProgressWithLabel loading={loading} value={progressFile} />
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
};

export default CreateCustomPage;
