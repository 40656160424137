/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { toast } from "react-toastify";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";
import { Box, Card, CircularProgress, Stack } from "@mui/material";

import domainApi from "api/domain";
import mediaApi from "api/media";

import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import MDFile from "components/MDFile";
import { useForm } from "react-hook-form";
import MDInput from "components/MDInput";

function DomainMETA() {
  const { domainName } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    control,
  } = useForm();

  const [domainInfo, setDomainInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDomainInfo = async () => {
    try {
      const res = await domainApi.getDomainInfo(domainName);
      if (!res.domain.isMETAActivate) navigate(`/dashboard/${domainName}`);
      setValue("meta", res.domain.meta);
      setDomainInfo(res);
    } catch (error) {
      navigate("/dashboard");
    }
  };

  const uploadImageHandler = async (domainId, path, data, option) => {
    try {
      const formData = new FormData();
      formData.append("image", data);
      const res = await mediaApi.updateDomainMeta(domainId, path, formData, option);
      getDomainInfo();
      toast.success(`Image updated successfully`);
      return true;
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
      return false;
    }
  };

  const updateDomain = async () => {
    const values = getValues();
    const body = {
      meta: { ...values.meta, image: undefined, icon: undefined },
    };
    setLoading(true);
    try {
      const res = await domainApi.updateDomainMeta(domainInfo.domain._id, body);
      await getDomainInfo();
      toast.success(`Domain updated successfully`);
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDomainInfo();
  }, [domainName]);

  const metaImage = watch("meta.image");
  const metaIcon = watch("meta.icon");

  return domainInfo ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card sx={{ padding: 5, marginBottom: 5 }}>
          <MDTypography>META Files</MDTypography>
          <Stack direction="row" justifyContent="space-around">
            <MDFile
              title={"Image"}
              value={metaImage}
              domainId={domainInfo.domain._id}
              path={"meta.image"}
              onClickHandler={uploadImageHandler}
            />
            <MDFile
              title={"Icon"}
              value={metaIcon}
              domainId={domainInfo.domain._id}
              path={"meta.icon"}
              onClickHandler={uploadImageHandler}
            />
          </Stack>
        </Card>
        <Card sx={{ padding: 5, marginBottom: 10 }}>
          <Stack spacing={4}>
            <MDTypography>META Services</MDTypography>
            <MDInput
              label="Title"
              fullWidth
              register={register("meta.title", { required: true })}
            />
            <MDInput
              label="Description"
              fullWidth
              register={register("meta.description", { required: true })}
            />
            <MDInput label="Type" fullWidth register={register("meta.type", { required: true })} />
            <MDInput label="Url" fullWidth register={register("meta.url", { required: true })} />

            <MDButton color="success" onClick={updateDomain}>
              Update
              {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
            </MDButton>
          </Stack>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default DomainMETA;
